import React, { useEffect, useState } from 'react'
import cn from 'classnames'
import styles from './Trend.module.sass'
import { Link } from 'react-router-dom'
import Dropdown from '../../../components/Dropdown'
import { AreaChart, Area, ResponsiveContainer, YAxis } from 'recharts'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { usePricingMonitor } from 'hooks/usePricingMonitor'

const navigation = ['All', 'DeFi', 'Innovation', 'POS', 'NFT', 'POW', 'Storage']

const MarketCard = ({ marketItem }) => {
  const marketPriceData = usePricingMonitor(marketItem.instanceId)
  return (
    <>
      <div className={styles.col}>
        <div className={styles.item}>
          <div className={cn('currency-icon', styles.icon)}>
            <img
              src={`images/content/crypto/${
                marketPriceData.pairName?.split('-')?.[0]
              }.svg`}
              alt={marketPriceData.pairName?.split('-')?.[0]}
            />
          </div>
          <div className={styles.details}>
            <span className={styles.subtitle}>{marketItem.title}</span>
            <span className={styles.currency}>{marketItem.currency}</span>
          </div>
        </div>
      </div>
      <div className={styles.col}> $ {marketPriceData?.currentPrice?.$numberDecimal ?? marketPriceData?.currentPrice}</div>
      <div className={styles.col}>
        <div
          className={
            parseFloat(marketPriceData.percentage) >= 0
              ? styles.positive
              : styles.negative
          }
        >
          {marketPriceData?.percentage?.$numberDecimal ?? marketPriceData.percentage} %
        </div>
      </div>
    </>
  )
}

const ChartItem = ({ market }) => {
  const [data, setData] = useState([])
  const { highKlines, loading } = useSelector((state) => state.crypto.klines)
  
  const found = (highKlines || []).find((x) => x.name === market.pairName);
  useEffect(() => {
    if (found) {
      setData(found?.data?.map((Price, index) => ({ name: index, Price })))
    }
  }, [found])

  return (
    <>
      <div className={styles.col}>
        <div className={styles.chart}>
          <ResponsiveContainer width="100%" height="100%">
            <AreaChart
              width={500}
              height={400}
              data={data}
              margin={{
                top: 0,
                right: 0,
                left: 0,
                bottom: 0,
              }}
            >
              <defs>
                <linearGradient id="colorPrice" x1="0" y1="0" x2="0" y2="1">
                  <stop offset="5%" stopColor="#45B36B" stopOpacity={0.8} />
                  <stop offset="95%" stopColor="#45B36B" stopOpacity={0} />
                </linearGradient>
              </defs>
              <YAxis hide domain={['auto', 'auto']} />
              {/* <Tooltip /> */}
              <Area
                type="monotone"
                dataKey="Price"
                stroke="#45B36B"
                fillOpacity={1}
                fill="url(#colorPrice)"
              />
            </AreaChart>
          </ResponsiveContainer>
        </div>
      </div>
    </>
  )
}

import { fetchMarkets } from 'store/actions'

import Aos from 'aos'
import 'aos/dist/aos.css'

const Learn = () => {

  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(fetchMarkets({
      type: 'SPOT',
    }))
  }, [])

  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);

  const { t } = useTranslation()
  const [sorting, setSorting] = useState(navigation[0])
  const { markets = [] } = useSelector((state) => state?.crypto?.markets)
  return (
    <div style={{padding: '50px 0'}} className={cn('section', styles.section)}>
      <div className={cn('container', styles.container)}>
        <div className={styles.line}>
          <h2 className={cn('h2', styles.title)}>Market Trends</h2>
          <Link className={cn('button-stroke', styles.button)} to="/market">
            View more
          </Link>
        </div>
        {/* <div className={styles.nav}>
          {navigation.map((x, index) => (
            <button
              className={cn(styles.link, {
                [styles.active]: index === activeIndex,
              })}
              onClick={() => setActiveIndex(index)}
              key={index}
            >
              {x}
            </button>
          ))}
        </div> */}
        <div className={styles.dropdown}>
          <Dropdown
            className={styles.dropdown}
            value={sorting}
            setValue={setSorting}
            options={navigation}
          />
        </div>
        <div className={styles.table}>
          <div className={styles.row}>
            <div className={styles.col}>#</div>
            <div className={styles.col}>{t('Name')}</div>
            <div className={styles.col}>{t('Price')}</div>
            <div className={styles.col}>{'24h change'}</div>
            <div className={styles.col}>{t('Chart')}</div>
            <div className={styles.col}>{t('Trade')}</div>
          </div>
          {markets.map((x, index) => (
            <Link data-aos="fade-up" className={styles.row} to={'/exchange'} key={index}>
              <div className={cn('market-last-column', styles.col)}>
                {index + 1}
              </div>
              <MarketCard marketItem={x} />
              <ChartItem market={x} />

              <div className={cn('market-first-column', styles.col)}>
                <button
                  className={cn('button-stroke button-small', styles.button)}
                >
                  Trade
                </button>
              </div>
            </Link>
          ))}
        </div>
      </div>
    </div>
  )
}

export default Learn
