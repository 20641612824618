// import socketio from 'socket.io-client'
// import { GetToken } from '../apis/api_helper'
// import Config from '../config'
// import { socketEvents } from './events'

// export const socket = socketio.connect(Config.SOCKET_URL, {
//   auth: {
//     cpToken: GetToken(),
//   },
//   transports: ['websocket'],
// })

// export const initSockets = ({ state, setState }) => {
//   socketEvents({
//     state,
//     setState,
//   })
// }

// socket/index.js
import socketio from 'socket.io-client'
import { GetToken } from '../apis/api_helper'
import Config from '../config'
import { setupEventHandlers } from './events'

const RECONNECTION_ATTEMPTS = 5
const RECONNECTION_DELAY = 3000

export const socket = socketio.connect(Config.SOCKET_URL, {
  auth: {
    cpToken: GetToken(),
  },
  transports: ['websocket'],
  reconnectionAttempts: RECONNECTION_ATTEMPTS,
  reconnectionDelay: RECONNECTION_DELAY,
  autoConnect: false // We'll manually connect after setup
})

export const initSockets = ({ state, setState }) => {
  // Connect after setting up event handlers
  socket.connect()
  
  // Setup connection state tracking
  socket.on('connect', () => {
    console.log("SOCKET CONNECTTED")
    setState(prev => ({
      ...prev,
      socketConnected: true
    }))
    
    // Resubscribe to channels if needed
    if (state.markupId) {
      socket.emit('subscribe', [`pricing_${state.markupId}`])
    }
  })

  socket.on('disconnect', () => {
    setState(prev => ({
      ...prev,
      socketConnected: false
    }))
  })

  socket.on('connect_error', (error) => {
    console.error('Socket connection error:', error)
    setState(prev => ({
      ...prev,
      socketError: error.message
    }))
  })

  // Initialize event handlers
  setupEventHandlers({ state, setState })
}

// Cleanup function for unmounting
export const cleanupSockets = () => {
  if (socket.connected) {
    socket.disconnect()
  }
}