import React, { useState } from 'react'
import cn from 'classnames'
import styles from './SignIn.module.sass'
import Login from '../../components/Login'
import Icon from '../../components/Icon'
import Form from './Form'
import Scan from './Scan'
import { useTranslation } from 'react-i18next'
import { clientName } from 'content'

const SignIn = (props) => {
  const [scan, setScan] = useState(false)
  const { t } = useTranslation()
  return (
    <Login
      content="Don’t have an account?"
      linkText="Sign up for free"
      linkUrl="/register/crypto/live"
    >
      <div className={styles.login}>
        <div className={styles.top}>
          <h3 className={cn('h3', styles.title)}>
            {t('Login to')} {clientName}
          </h3>
          <div className={styles.info}>
            {t('Please ensure you are visiting the correct url.')}
          </div>
          <div className={styles.correct}>
            <Icon name="lock" size="24" />
            <div className={styles.url}>{`https://demo.${clientName}.com/login`}</div>
          </div>
        </div>
        {scan ? <Scan /> : <Form {...props} onScan={() => setScan(true)} />}
      </div>
    </Login>
  )
}

export default SignIn
