/* eslint-disable no-console */
import React, { useEffect } from 'react';
import styles from './BannerSections.module.css';
import useDarkMode from 'use-dark-mode';
import Aos from 'aos';
import 'aos/dist/aos.css';

export default function BannerSections() {
  const colorMode = useDarkMode();
  console.log(colorMode);

  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);

  return (
    <div
      className={`${styles.banner} ${colorMode.value ? styles.dark : ''}`}
      style={{ backgroundColor: colorMode.value ? '#18191d' : '#f7f7f7' }}
    >
      <div className={styles.container}>
        <div className={styles.grid}>
          <div data-aos="fade-right" className={styles.banner__left}>
            <div className={styles.banner__content}>
              <h1
                className={styles.heading}
                style={{ color: colorMode.value ? '#fff' : '#23262f' }}
              >
                A trusted and secure cryptocurrency exchange.
              </h1>
              <p className={styles.text}>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua.
              </p>
              <a href="/" className={styles.btnAction}>
                Trading Now
              </a>
            </div>
          </div>
          <div data-aos="fade-left" className={styles.banner__image}>
            <img
              src="/img/banner-02.png"
              alt="Banner"
              height="448"
              width="655"
            />
          </div>
        </div>
      </div>
    </div>
  );
}
