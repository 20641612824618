import React, { useEffect } from "react";
import styles from "./HomeSectionFive.module.css"; 
import Aos from "aos";
import 'aos/dist/aos.css';
import useDarkMode from "use-dark-mode";

export default function HomeSectionFive() {

    const darkMode = useDarkMode();

    useEffect(() => {
        Aos.init({ duration: 2000 });
      }, []);

  return (
    <section className={`${styles.workSection} ${darkMode.value ? styles.dark : ""}`}>
      <div className={styles.container}>
        <div data-aos="fade-up" className={styles.blockText}>
          <h3>How It Work+</h3>
          <p>Stacks is a production-ready library of stackable content blocks built in React Native.</p>
        </div>
        <div className={styles.grid}>
          {/* Step 1 */}
          <div data-aos="fade-up" className={styles.workMain}>
            <div className={styles.workBox}>
              <div className={styles.image}>
                <img
                  src="/img/five_img.png"
                  alt="Step 1"
                  height={96}
                  width={96}
                  className={styles.rotateImg}
                />
              </div>
              <div className={styles.content}>
                <p className={styles.step}>Step 1</p>
                <a href="#" className={styles.title}>Sign Up</a>
                <p className={styles.text}>Stacks Is A Production-Ready Library Of Stackable Content Blocks Built In React Native.</p>
              </div>
              {/* <div className={styles.connectImgDot}>
                <img
                  src="/img/connect_img.png"
                  alt="Connect"
                  height={12}
                  width={160}
                  className={styles.line}
                />
              </div> */}
            </div>
          </div>

          {/* Step 2 */}
          <div data-aos="fade-up" data-aos-delay="300" className={styles.workMain}>
            <div className={styles.workBox}>
              <div className={styles.image}>
                <img
                  src="/img/five_img02.png"
                  alt="Step 2"
                  height={96}
                  width={96}
                  className={styles.rotateImg}
                />
              </div>
              <div className={styles.content}>
                <p className={styles.step}>Step 2</p>
                <a href="#" className={styles.title}>Verify</a>
                <p className={styles.text}>Stacks Is A Production-Ready Library Of Stackable Content Blocks Built In React Native.</p>
              </div>
              {/* <div className={styles.connectImgDot}>
                <img
                  src="/img/connect_img.png"
                  alt="Connect"
                  height={12}
                  width={160}
                  className={styles.line}
                />
              </div> */}
            </div>
          </div>

          {/* Step 3 */}
          <div data-aos="fade-up" data-aos-delay="600" className={styles.workMain}>
            <div className={styles.workBox}>
              <div className={styles.image}>
                <img
                  src="/img/five_img03.png"
                  alt="Step 3"
                  height={96}
                  width={96}
                  className={styles.rotateImg}
                />
              </div>
              <div className={styles.content}>
                <p className={styles.step}>Step 3</p>
                <a href="#" className={styles.title}>Deposit</a>
                <p className={styles.text}>Stacks Is A Production-Ready Library Of Stackable Content Blocks Built In React Native.</p>
              </div>
              {/* <div className={styles.connectImgDot}>
                <img
                  src="/img/connect_img.png"
                  alt="Connect"
                  height={12}
                  width={160}
                  className={styles.line}
                />
              </div> */}
            </div>
          </div>

          {/* Step 4 */}
          <div data-aos="fade-up" data-aos-delay="900" className={styles.workMain}>
            <div className={styles.workBox}>
              <div className={styles.image}>
                <img
                  src="/img/five_img04.png"
                  alt="Step 4"
                  height={96}
                  width={96}
                  className={styles.rotateImg}
                />
              </div>
              <div className={styles.content}>
                <p className={styles.step}>Step 4</p>
                <a href="#" className={styles.title}>Start Trading</a>
                <p className={styles.text}>Stacks Is A Production-Ready Library Of Stackable Content Blocks Built In React Native.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}