import React, { useEffect, useState } from 'react'
import cn from 'classnames'
import styles from './Exchange.module.sass'
import Main from './Main'
import Balance from './Balance'
import Currency from './Currency'
import Trades from './Trades'
import Table from './Table'
import { useMediaQuery } from 'react-responsive'
import Charts from './Charts'
import { useSelector } from 'react-redux'

const navigation = ['Chart', 'Order books', 'Trades']

const StandardLayout = ({ children, ...props }) => {
  const [activeIndex, setActiveIndex] = useState(0)
  const isTablet = useMediaQuery({ query: '(max-width: 1023px)' })
  const markets = useSelector((state) => state.crypto.markets.markets)
  const {
    selectedMarket,
    setSelectedMarket,
  } = props
  useEffect(() => {
    if (markets?.length > 0) {
      setSelectedMarket(markets?.[0]?.instanceId)
    }
  }, [markets])

  if (!selectedMarket) {
    return null
  }

  return (
    <div className={cn('exchange', styles.exchange)}>
      <Main selectedMarket={selectedMarket} />
      <div className={styles.nav}>
        {navigation.map((x, index) => (
          <button
            className={cn(styles.link, {
              [styles.active]: index === activeIndex,
            })}
            onClick={() => setActiveIndex(index)}
            key={index}
          >
            {x}
          </button>
        ))}
      </div>
      {isTablet ? (
        <>
          {children}
          {/* {activeIndex === 0 && (
            <div className={styles.box}>
              <Charts selectedMarket={selectedMarket} />
              <Table />
            </div>
          )}
          {activeIndex === 1 && (
            <div className={styles.box}>
              <Balance selectedMarket={selectedMarket} />
            </div>
          )}
          {activeIndex === 2 && (
            <div className={styles.box}>
              <Currency onMarketSelect={setSelectedMarket} />
              <Trades />
            </div>
          )} */}
        </>
      ) : (
        <div className={styles.row}>
          <div className={styles.col}>
            <Currency onMarketSelect={(x) => {
              setSelectedMarket(x)
            }} />
            <Trades selectedMarket={selectedMarket} />
          </div>
          <div className={styles.col}>
            <Charts selectedMarket={selectedMarket} />
            {children}
            <Table />
          </div>
          <div className={styles.col}>
            <Balance selectedMarket={selectedMarket} />
          </div>
        </div>
      )}
    </div>
  )
}

export default StandardLayout
