import React, { useEffect } from 'react'
import './styles/app.sass'
import './styles/custom.scss'
import './global.module.css'
import { Route, Routes, useLocation } from 'react-router-dom'
import { PORTALS, IS_IOS } from 'common/constants'
import { useSelector, useDispatch } from 'react-redux'
import cryptoRoutes from './routes/cryptoRoutes'
import AuthMiddleware from 'routes/middleware/Authmiddleware'
import Page from 'components/Page'
import ErrorBoundary from 'components/ErrorBoundary'
import 'react-toastify/dist/ReactToastify.css'
import SocketProvider from 'context'
import { checkPushNotificationSubscription } from "serviceWorkerRegistration"
import { receivedNotification, updatePushNotificationOption } from 'store/actions'

const lang = localStorage.getItem('I18N_LANGUAGE')

const channel = new BroadcastChannel('notification--exinitic--messages')

const routeSelector = (portal) => {
  let defaultRoutes
  switch (portal) {
    case PORTALS.CRYPTO:
      return cryptoRoutes
    default:
      return cryptoRoutes
  }
}
function App() {
  const dispatch = useDispatch()
  const location = useLocation()
  const notification = (!IS_IOS && Notification?.permission) || null
  const { portal } = useSelector((state) => state.Layout)
  let routes = routeSelector(portal)

  channel.onmessage = (event) => {
    const formattedData = formatReceivedNotification(event.data)
    if (formattedData._id) {
      dispatch(receivedNotification(formattedData))
    }
  }

  useEffect(async () => {
    const checkCurrentNotification = await checkPushNotificationSubscription()
    dispatch(updatePushNotificationOption(checkCurrentNotification))
  }, [notification])

  useEffect(() => {
    window.scroll(0, 0)
  }, [location])

  return (
    <ErrorBoundary>
      <SocketProvider>
        <Routes>
          {routes?.map((route, idx) => (
            <Route
              key={idx}
              path={route.path}
              element={
                <AuthMiddleware
                  isAuthProtected={route.isAuth}
                  isNotAuthProtected={route.isNotAuthProtected}
                  component={route.component}
                  layout={Page}
                  headerHide={route.headerHide}
                  footerHide={route.footerHide}
                  headerWide={route.headerWide}
                  stickyHeader={route.stickyHeader}
                />
              }
            />
          ))}
        </Routes>
      </SocketProvider>
    </ErrorBoundary>
  )
}

export default App
