import { useTranslation } from 'react-i18next'
import styles from './PrivacyPolicy.module.sass'
import cn from 'classnames'

const PrivacyPolicy = () => {
  const { t } = useTranslation()

  return (
    <div className={'container'}>
      <div className={cn('section')}>
        <div className={cn('h4', styles.title)}>{t('Privacy Policy')}</div>
        <div className={styles.dateContainer}>
          <div className="h5">Privacy Policy for Waltica</div>
          <div className="h5">Effective Date: 1st Jan 2025</div>
        </div>

        <div className={cn(styles.row)}>
          <div className={cn('h5', styles.subTitle)}>Introduction</div>
          <div className={cn(styles.peragraph)}>
            Waltica is committed to maintaining the trust and confidence of our
            clients and visitors to our web platform. In this Privacy Policy,
            we’ve provided detailed information on when and why we collect
            personal information, how we use it, the limited conditions under
            which we may disclose it to others, and how we keep it secure.
          </div>
        </div>

        <div className={cn(styles.row)}>
          <div className={cn('h5', styles.subTitle)}>Scope</div>
          <div className={cn(styles.peragraph)}>
            This Privacy Policy applies to all platforms, websites, and services
            offered by Waltica, except where otherwise noted. We refer to these
            collectively as our {`"`}Services.{`"`}
          </div>
        </div>

        <div className={cn(styles.row)}>
          <div className={cn('h5', styles.subTitle)}>
            Collection and Use of Personal Information
          </div>
        </div>

        <div className={cn(styles.row)}>
          <div className={cn(styles.row)}>
            <div className={cn(styles.peragraph)}>
              1.Types of Information Collected:
            </div>
          </div>
          <ul>
            <li>
              {' '}
              Contact information such as name, email address, mailing address,
              and phone number.
            </li>
            <li>
              {' '}
              Demographic information such as age, education, gender, interests,
              and zip code.
            </li>
            <li>
              {' '}
              Identity verification information such as images of your
              government-issued ID, passport, national ID card, or driving
              license.
            </li>
            <li>
              {' '}
              Residence verification information such as utility bill details or
              similar information.
            </li>
            <li>
              {' '}
              Financial information such as bank statements, trading
              information, and cryptocurrency wallet addresses.
            </li>
            <li>
              {' '}
              Transaction information such as public blockchain data
              (transaction histories), trading data, and account balances.
            </li>
          </ul>
        </div>

        <div className={cn(styles.row)}>
          <div className={cn(styles.row)}>
            <div className={cn(styles.peragraph)}>
              2.How We Use Your Information:
            </div>
          </div>

          <ul>
            <li> To facilitate, operate, and provide our Services.</li>
            <li>
              {' '}
              To verify your identity in compliance with know your customer
              (KYC), anti-money laundering (AML) requirements, and other
              regulatory standards.
            </li>
            <li>
              {' '}
              To protect our platform against fraud, unauthorized transactions
              (such as money laundering), claims or other liabilities.
            </li>
            <li>
              {' '}
              To provide customer support, manage accounts, and send updates
              about your account.
            </li>
            <li>
              {' '}
              For marketing purposes, including sending you promotional
              materials that may be of interest to you.
            </li>
          </ul>
        </div>

        <div className={cn(styles.row)}>
          <div className={cn('h5', styles.subTitle)}>Children{`'`}s Privacy</div>
          <div className={cn(styles.peragraph)}>
            Our Services are not directed to individuals under the age of 18. We
            do not knowingly collect personal information from children under
            18. If we become aware that a child under 18 has provided us with
            personal information, we take steps to remove such information and
            terminate the child{`'`}s account.
          </div>
        </div>

        <div className={cn(styles.row)}>
          <div className={cn('h5', styles.subTitle)}>Data Security</div>
          <div className={cn(styles.peragraph)}>
            We implement robust security measures to ensure the confidentiality
            of your personal information. These include encryption, firewalls,
            and secure socket layer technology (SSL). However, no method of
            transmission over the Internet, or method of electronic storage, is
            100% secure; as such, we cannot guarantee its absolute security.
          </div>
        </div>

        <div className={cn(styles.row)}>
          <div className={cn('h5', styles.subTitle)}>Your Rights</div>
          <div className={cn(styles.peragraph)}>
            You have certain rights regarding the personal information we hold
            about you. These may include the rights to access, correct, delete,
            or restrict the use of your information, or to request portability
            of your personal information. You also have the right to withdraw
            consent at any time for any processing carried out on the basis of
            consent.
          </div>
        </div>

        <div className={cn(styles.row)}>
          <div className={cn('h5', styles.subTitle)}>
            Cookies and Other Tracking Technologies
          </div>
          <div className={cn(styles.peragraph)}>
            We may use cookies and similar tracking technologies to track the
            activity on our Service and we hold certain information. Cookies are
            files with a small amount of data which may include an anonymous
            unique identifier. You are free to decline our cookies if your
            browser or browser add-on permits, except our cookies are required
            to prevent fraud or ensure the security of websites we control.
          </div>
        </div>

        <div className={cn(styles.row)}>
          <div className={cn('h5', styles.subTitle)}>
            Data Sharing and Disclosure
          </div>
          <div className={cn(styles.peragraph)}>
            We do not sell or rent your personal information to third parties.
            We may share your information with third-party service providers to
            perform tasks on our behalf and to assist us in providing our
            Services, such as payment processing and data analysis.
          </div>
        </div>

        <div className={cn(styles.row)}>
          <div className={cn('h5', styles.subTitle)}>Data Security</div>
          <div className={cn(styles.peragraph)}>
            We implement robust security measures to ensure the confidentiality
            of your personal information. These include encryption, firewalls,
            and secure socket layer technology (SSL). However, no method of
            transmission over the Internet, or method of electronic storage, is
            100% secure; as such, we cannot guarantee its absolute security.
          </div>
        </div>

        <div className={cn(styles.row)}>
          <div className={cn('h5', styles.subTitle)}>
            Changes to This Privacy Policy
          </div>
          <div className={cn(styles.peragraph)}>
            We may update our Privacy Policy from time to time. We will notify
            you of any changes by posting the new Privacy Policy on this page.
            You are advised to review this Privacy Policy periodically for any
            changes.
          </div>
        </div>

        <div className={cn(styles.row)}>
          <div className={cn('h5', styles.subTitle)}>Contact Us</div>
          <div className={cn(styles.peragraph)}>
            If you have any questions or suggestions about our Privacy Policy,
            do not hesitate to contact us at info@finiticX.com.
          </div>
        </div>

        <div className={cn(styles.row)}>
          <div className={cn(styles.peragraph)}>
            By using our Services, you acknowledge that you have read and
            understand this Privacy Policy.
          </div>
        </div>

        <div className={cn(styles.row)}>
          <div className={cn(styles.peragraph)}>
            © 2025 Waltica. All rights reserved.
          </div>
        </div>

        {/* <div className={cn(styles.row)}>
          <div className={cn('pera', styles.peragraph)}>
            1. Quis occaecat exercitation culpa ut eu. Adipisicing laboris enim
            adipisicing ipsum eu amet non cupidatat eu voluptate occaecat eu
            aute. Amet cillum aliquip dolore minim dolore mollit ullamco dolor
            culpa velit est.
          </div>
        </div>
        <div>
          <div className={cn('h5', styles.subTitle)}>
            1. Ex ullamco aute elit ullamco nulla duis est et deserunt laboris.
          </div>
          <ol>
            <li>
              {' '}
              Non consectetur Lorem minim cillum nulla pariatur amet nulla ex
              elit irure ullamco. Fugiat deserunt incididunt dolor do occaecat
              sunt. Duis consequat laboris et est in irure esse Lorem qui aliqua
              amet qui anim pariatur. Ullamco labore commodo labore anim est
              amet. Aute ad aliqua nisi deserunt dolore. Consectetur esse sit
              aute qui dolor adipisicing voluptate aliqua nostrud proident quis
              aliqua eiusmod.
            </li>
            <li>
              {' '}
              Ut in exercitation sunt ex eiusmod. Velit fugiat officia labore eu
              non. Irure commodo ipsum proident qui culpa culpa reprehenderit
              dolor id. Voluptate eiusmod laboris amet tempor laboris. Dolor
              officia occaecat exercitation minim id aliqua et. Mollit magna
              ullamco consectetur cillum ullamco duis incididunt ea nisi
              exercitation. Esse sint aliquip aliqua cupidatat aute nisi eu
              ullamco deserunt excepteur est.
            </li>
            <li>
              {' '}
              Sint et aliquip est dolor magna sit ut incididunt quis duis.
              Laboris ad qui deserunt laboris exercitation anim esse minim
              voluptate do aliquip nulla fugiat. Eu culpa cupidatat amet minim
              voluptate veniam do et.
            </li>
          </ol>
        </div>

        <div className={styles.row}>
          <ol>
            <div>
              <li> Et aliquip enim voluptate ut excepteur est nulla.</li>
              <li> Et aliquip enim voluptate ut excepteur est nulla.</li>
              <li> Et aliquip enim voluptate ut excepteur est nulla.</li>
            </div>
          </ol>
        </div>

        <div className={styles.row}>
          <div className={styles.subTitle}>
            In voluptate esse dolor in magna anim elit anim duis velit et
            voluptate sint quis.
          </div>
          <div>
            Et non eiusmod labore non deserunt mollit fugiat enim labore
            adipisicing ullamco cillum non nulla. Velit ipsum quis anim nulla.
            Id anim Lorem irure laboris proident qui laborum labore non dolore
            cillum. Amet ut magna enim sunt. Nisi reprehenderit elit dolor esse
            aliqua. Dolore eiusmod incididunt consectetur aliqua eu occaecat
            nostrud incididunt consequat. Dolor in minim in do do ad velit
            cupidatat laborum enim.
          </div>
        </div>

        <div className={styles.row}>
          <div className={styles.subTitle}>
            In voluptate esse dolor in magna anim elit anim duis velit et
            voluptate sint quis.
          </div>
          <div>
            Et non eiusmod labore non deserunt mollit fugiat enim labore
            adipisicing ullamco cillum non nulla. Velit ipsum quis anim nulla.
            Id anim Lorem irure laboris proident qui laborum labore non dolore
            cillum. Amet ut magna enim sunt. Nisi reprehenderit elit dolor esse
            aliqua. Dolore eiusmod incididunt consectetur aliqua eu occaecat
            nostrud incididunt consequat. Dolor in minim in do do ad velit
            cupidatat laborum enim.
          </div>
        </div>

        <div className={styles.row}>
          <div className={styles.subTitle}>
            In voluptate esse dolor in magna anim elit anim duis velit et
            voluptate sint quis.
          </div>
          <div>
            Et non eiusmod labore non deserunt mollit fugiat enim labore
            adipisicing ullamco cillum non nulla. Velit ipsum quis anim nulla.
            Id anim Lorem irure laboris proident qui laborum labore non dolore
            cillum. Amet ut magna enim sunt. Nisi reprehenderit elit dolor esse
            aliqua. Dolore eiusmod incididunt consectetur aliqua eu occaecat
            nostrud incididunt consequat. Dolor in minim in do do ad velit
            cupidatat laborum enim.
          </div>
        </div>

        <div className={cn('indentation', styles.indentation)}>
          <div className={styles.row}>
            <div className={styles.subTitle}>
              In voluptate esse dolor in magna anim elit anim duis velit et
              voluptate sint quis.
            </div>
            <div>
              Et non eiusmod labore non deserunt mollit fugiat enim labore
              adipisicing ullamco cillum non nulla. Velit ipsum quis anim nulla.
              Id anim Lorem irure laboris proident qui laborum labore non dolore
              cillum. Amet ut magna enim sunt. Nisi reprehenderit elit dolor
              esse aliqua. Dolore eiusmod incididunt consectetur aliqua eu
              occaecat nostrud incididunt consequat. Dolor in minim in do do ad
              velit cupidatat laborum enim.
            </div>
            <div>
              Et non eiusmod labore non deserunt mollit fugiat enim labore
              adipisicing ullamco cillum non nulla. Velit ipsum quis anim nulla.
              Id anim Lorem irure laboris proident qui laborum labore non dolore
              cillum. Amet ut magna enim sunt. Nisi reprehenderit elit dolor
              esse aliqua. Dolore eiusmod incididunt consectetur aliqua eu
              occaecat nostrud incididunt consequat. Dolor in minim in do do ad
              velit cupidatat laborum enim.
            </div>
          </div>
        </div> */}
      </div>
    </div>
  )
}

export default PrivacyPolicy
