import {
  FETCH_DEPOSIT_WALLETS_FAILED,
  FETCH_DEPOSIT_WALLETS_START,
  FETCH_DEPOSIT_WALLETS_SUCCESS,
  // FETCH_WALLET_REPORT_FAILED,
  // FETCH_WALLET_REPORT_START,
  // FETCH_WALLET_REPORT_SUCCESS,
  // REQUEST_WALLET_FX_TRANSFER_ERROR,
  // REQUEST_WALLET_FX_TRANSFER_START,
  // REQUEST_WALLET_FX_TRANSFER_SUCCESS,
} from './actionTypes'

const initialState = {
  loading: false,
  depositWallets: [],
  wallets: [],
  allWallets: {}, // it has all type ['total','trading', 'funding', 'margin', 'futures' ]
  transferLoading: false,
  clearingCounter: 0,
  report: {
    loading: false,
    data: [],
    pagination: {},
  },
}

const htxReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_DEPOSIT_WALLETS_START:
      state = {
        ...state,
        loading: true,
      }
      break
    case FETCH_DEPOSIT_WALLETS_FAILED:
      state = {
        ...state,
        loading: false,
        error: action.payload,
      }
      break

    case FETCH_DEPOSIT_WALLETS_SUCCESS:
      state = {
        ...state,
        loading: false,
        error: '',
        depositWallets: action.payload,
      }
      break

    // case REQUEST_WALLET_FX_TRANSFER_START:
    //   state = {
    //     ...state,
    //     transferLoading: true,
    //   }
    //   break
    // case REQUEST_WALLET_FX_TRANSFER_SUCCESS:
    //   state = {
    //     ...state,
    //     transferLoading: false,
    //     clearingCounter: state.clearingCounter + 1,
    //   }
    //   break
    // case REQUEST_WALLET_FX_TRANSFER_ERROR:
    //   state = {
    //     ...state,
    //     transferLoading: false,
    //   }
    //   break
    // case FETCH_WALLET_REPORT_START:
    //   state = {
    //     ...state,
    //     report: {
    //       ...state.report,
    //       loading: true,
    //     },
    //   }
    //   break
    // case FETCH_WALLET_REPORT_SUCCESS:
    //   state = {
    //     ...state,
    //     report: {
    //       ...state.report,
    //       loading: false,
    //       data: action.payload.docs,
    //       pagination: {
    //         ...action.payload,
    //       },
    //     },
    //   }
    //   delete state.report.pagination.docs
    //   break
    // case FETCH_WALLET_REPORT_FAILED:
    //   state = {
    //     ...state,
    //     report: {
    //       ...state.report,
    //       loading: false,
    //     },
    //   }
    //   break
    default:
      state = { ...state }
      break
  }
  return state
}

export default htxReducer
