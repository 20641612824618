import * as Yup from 'yup'

export const SignupValidationSchema = Yup.object().shape({
  firstName: Yup.string().matches(/^[^0-9]*$/, 'First name cannot contain numbers').max(50, 'First name cannot exceed 50 characters').required('First name is required'),
  lastName: Yup.string().matches(/^[^0-9]*$/, 'Last name cannot contain numbers').max(50, 'Last name cannot exceed 50 characters').required('Last name is required'),
  country: Yup.string().required('Country is required'),
  phone: Yup.string()
    .matches(/^\d+$/, 'Phone number must contain only numbers')
    .required('Phone number is required'),
  email: Yup.string().email('Invalid email').required('Email is required'),
  password: Yup.string()
    .required('Password is required')
    .matches(
      // regex pattern
      /^(?=.*[a-z])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
      'Password length must be 8, at least one uppercase letter, one lowercase letter, one special character and one number',
    ),
})
