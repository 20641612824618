import React, { useEffect } from "react";
import styles from "./HomeSectionFour.module.css"; // Import CSS module
import useDarkMode from "use-dark-mode";
import Aos from "aos";
import 'aos/dist/aos.css';

export default function HomeSectionFour() {

    const darkMode = useDarkMode();

    useEffect(() => {
        Aos.init({ duration: 2000 });
    }, []);

  return (
    <section className={`${styles.aboutSection} ${darkMode.value ? styles.dark : ""}`}>
      <div className={styles.container}>
        <div className={styles.grid}>
          {/* Left Column */}
          <div data-aos="fade-right" className={styles.leftColumn}>
            <div className={styles.aboutImage}>
              <img
                src="/img/sulema_img.png"
                alt="About img"
                height={528}
                width={558}
                className={styles.image}
              />
              <div className={`${styles.tradersBox} ${styles.tradersBox_1}`}>
                <div className={styles.icon}>
                  <img
                    src="/img/illaster.svg"
                    alt="Icon"
                    height={23}
                    width={29}
                  />
                </div>
                <div className={styles.content}>
                  <h6>198+</h6>
                  <p>Countries</p>
                </div>
              </div>
              <div className={`${styles.tradersBox} ${styles.tradersBox_2}`}>
                <div className={styles.icon}>
                  <img src="/img/org.svg" alt="Icon" height={23} width={29} />
                </div>
                <div className={styles.content}>
                  <h6>350+</h6>
                  <p>Trading Pairs</p>
                </div>
              </div>
              <div className={`${styles.tradersBox} ${styles.tradersBox_3}`}>
                <div className={styles.icon}>
                  <img src="/img/tro.svg" alt="Icon" height={23} width={29} />
                </div>
                <div className={styles.content}>
                  <h6>20 million+</h6>
                  <p>Trades</p>
                </div>
              </div>
            </div>
          </div>

          {/* Right Column */}
          <div data-aos="fade-left" className={styles.rightColumn}>
            <div className={styles.aboutContent}>
              <h3>We Are The Most Trusted Cryptocurrency Platform.</h3>
              <p>
                We believe Cryptolly is here to stay — and that a future worth
                building is one which opens its doors and invites everyone in.
              </p>
              <ul className={styles.list}>
                <li>
                  <div style={{ backgroundColor: '#d335351a' }}  className={styles.icon}>
                    <img
                      src="/img/eye.svg"
                      alt="Icon"
                      height={23}
                      width={29}
                    />
                  </div>
                  <div className={styles.content}>
                    <h6>Clarity</h6>
                    <p>
                      We Help You Make Sense Of The Coins, The Terms, The Dense
                      Charts And Market Changes.
                    </p>
                  </div>
                </li>
                <li>
                  <div style={{ backgroundColor: '#58bd7d1a' }} className={`${styles.icon} ${styles.green}`}>
                    <img
                      src="/img/true.svg"
                      alt="Icon"
                      height={23}
                      width={29}
                    />
                  </div>
                  <div className={styles.content}>
                    <h6>Confidence</h6>
                    <p>
                      Our Markets Are Always Up To Date, Sparking Curiosity With
                      Real-World Relevance.
                    </p>
                  </div>
                </li>
                <li>
                  <div style={{ backgroundColor: '#3772ff1a' }} className={`${styles.icon} ${styles.blue}`}>
                    <img
                      src="/img/namag.svg"
                      alt="Icon"
                      height={23}
                      width={29}
                    />
                  </div>
                  <div className={styles.content}>
                    <h6>Community</h6>
                    <p>
                      We Supports The Crypto Community, Putting Data In The Hands
                      Which Need It Most.
                    </p>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}