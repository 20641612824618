import { useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import cn from 'classnames'
import styles from './Footer.module.sass'
import Icon from '../Icon'
import Image from '../Image'
import { useTranslation } from 'react-i18next'
import useDarkMode from 'use-dark-mode'

const menu = [
  {
    title: 'Exchange',
    url: '/exchange',
  },
  {
    title: 'Buy crypto',
    url: '/buy-crypto',
  },
  {
    title: 'Market',
    url: '/market',
  },
  {
    title: 'Learn crypto',
    url: '/learn-crypto',
  },
  {
    title: 'Contact',
    url: '/contact',
  },
]

const products = [
  {
    title: 'Buy Crypto',
    url: '/buy-crypto',
  },
  {
    title: 'Spot Trade',
    url: '/exchange',
  },
  {
    title: 'Derivatives',
    url: '/exchange/futures',
  },
  // {
  //   title: 'Demo Trading',
  //   url: '/',
  // },
  {
    title: 'Affiliate Program',
    url: '/reward/affiliate',
  },
]

const support = [
  {
    title: 'Help Center',
    url: '/help',
  },
  {
    title: 'Contact Us',
    url: '/contact',
  },
  {
    title: 'FAQ',
    url: '/contact',
  },
  {
    title: 'Announcements',
    url: '/home',
  },
]

const services = [
  {
    title: 'Our Application',
    url: '/our-applications',
  },
  {
    title: 'Reward Hub',
    url: '/reward/task-centre',
  },
  // {
  //   title: 'Futures Bonus Center',
  //   url: '/',
  // },
  {
    title: 'Futures Fees',
    url: '/future-fees',
  },
  {
    title: 'Spot Fees',
    url: '/spot-fees',
  },
]

const terms = [
  {
    title: 'Privacy Policy',
    size: '16',
    url: '/privacy',
  },
  {
    title: 'Disclaimer',
    size: '18',
    url: '/disclaimer',
  },
  {
    title: 'About us',
    size: '16',
    url: '/about-us',
  },
  {
    title: 'User Agreement',
    size: '16',
    url: '/user-agreement',
  },
]

const socials = [
  {
    title: 'facebook',
    size: '16',
    url: 'https://www.facebook.com/finiticx?mibextid=uzlsIk',
  },
  {
    title: 'twitter',
    size: '18',
    url: 'https://x.com/finiticx?s=21',
  },
  {
    title: 'instagram',
    size: '16',
    url: 'https://www.instagram.com/finiticx?igsh=dDNzaTlta2xjaWtt',
  },
]

const Footer = () => {

  const darkMode = useDarkMode()

  const [visible, setVisible] = useState(false)
  const [email, setEmail] = useState('')
  const { pathname } = useLocation()
  const { t } = useTranslation()

  const handleSubmit = (e) => {
    alert()
  }

  return (
    <footer className={styles.footer}>
      <div className={styles.logoMCotainer}>
      <Link to="/" className={styles.logoLink}>
            <img
              src="/static/img/logo.png"
              alt="logo"
              // width={70}
              height={60}
            />
          </Link>
      </div>
      <div className={styles.body}>
        <div className={styles.foot}>
          <div className={cn(styles.container)}>
            <div className={styles.logoCotainer}>
            <Link style={{ marginRight: '20px' }} to="/" className={styles.logoLink}>
        <img
          src={darkMode.value ?
             "/static/img/logo.png"
              :
             "/static/img/logo-2.png"}
          alt="logo"
          height={50}
        />
           </Link>
            </div>
            <div className={styles.categoryCol}>
              <div className={styles.categoryTitle}>{t('Terms')}</div>
              <div className={styles.listCol}>
                {terms.map((x, index) => (
                  <Link
                    className={cn('footer-social', styles.link)}
                    to={x.url}
                    rel="noopener noreferrer"
                    key={index}
                  >
                    {x.title}
                  </Link>
                ))}
              </div>
            </div>
            <div className={styles.categoryCol}>
              <div className={styles.categoryTitle}>{t('Services')}</div>
              <div className={styles.listCol}>
                {services.map((x, index) => (
                  <Link
                    className={cn('footer-social', styles.link)}
                    to={x.url}
                    rel="noopener noreferrer"
                    key={index}
                  >
                    {x.title}
                  </Link>
                ))}
              </div>
            </div>
            <div className={styles.categoryCol}>
              <div className={styles.categoryTitle}>{t('Products')}</div>
              <div className={styles.listCol}>
                {products.map((x, index) => (
                  <Link
                    className={cn('footer-social', styles.link)}
                    to={x.url}
                    rel="noopener noreferrer"
                    key={index}
                  >
                    {x.title}
                  </Link>
                ))}
              </div>
            </div>
            <div className={styles.categoryCol}>
              <div className={styles.categoryTitle}>{t('Support')}</div>
              <div className={styles.listCol}>
                {/* {support.map((x, index) => (
                  <a
                    className={cn('footer-social', styles.link)}
                    href={x.url}
                    rel="noopener noreferrer"
                    key={index}
                  >
                    {x.title}
                  </a>
                ))} */}
                {support.map((x, index) => (
                  <Link
                    className={cn('footer-social', styles.link)}
                    to={x.url}
                    rel="noopener noreferrer"
                    key={index}
                  >
                    {x.title}
                  </Link>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.foot}>
        <div className={cn('container', styles.container)}>
          <div className={cn('copyright', styles.copyright)}>
            {t('Copyright 2025 Waltica LLC. All rights reserved')}
          </div>
          <div className={styles.socials}>
            {socials.map((x, index) => (
              <a
                className={cn('footer-social', styles.social)}
                href={x.url}
                rel="noopener noreferrer"
                key={index}
                target="_blank"
              >
                <Icon name={x.title} size={x.size} />
              </a>
            ))}
          </div>
          <div className={styles.socials}>
            <a
              className={cn('footer-social', styles.social)}
              href={'https://t.me/finiticxofficial'}
              rel="noopener noreferrer"
              target="_blank"
            >
              <img
                src="/images/content/crypto/telegram.svg"
                srcSet="/images/content/crypto/telegramLight.png"
                height={18}
              />
            </a>
            {/* <a
              className={cn('footer-social', styles.social)}
              href={'https://www.linkedin.com/company/finiticx/'}
              rel="noopener noreferrer"
              target="_blank"
            >
              <img
                src="/images/content/crypto/youtube.png"
                srcSet="/images/content/crypto/youtubeLight.png"
                height={18}
              />
            </a> */}
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer
