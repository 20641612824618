import { useContext, useEffect, useMemo } from 'react'
import cn from 'classnames'
import { Link } from 'react-router-dom'
import styles from './Cards.module.sass'
import styles2 from './Cards.module.css'
import { useSelector } from 'react-redux'
import SocketContext from 'context/context'
import { usePricingMonitor } from 'hooks/usePricingMonitor'

import Aos from 'aos'
import 'aos/dist/aos.css'

const items = [
  {
    title: 'BTC/USDT',
    instanceId: 'BTC-USDT',
    image: 'images/content/crypto/BTC.svg',
    url: '/exchange',
  },
  {
    title: 'ETH/USDT',
    instanceId: 'ETH-USDT',
    image: 'images/content/crypto/ETH.svg',
    url: '/exchange',
  },
  {
    title: 'TRX/USDT',
    instanceId: 'TRX-USDT',
    image: 'images/content/crypto/TRX.svg',
    url: '/exchange',
  },
  {
    title: 'XRP/USDT',
    instanceId: 'XRP-USDT',
    image: 'images/content/crypto/XRP.svg',
    url: '/exchange',
  },
]

const MarketCard = ({ marketItem }) => {
  const marketPriceData = usePricingMonitor(marketItem.instanceId)
  
  return (
    <>
      <div className={styles.icon}>
        <img src={marketItem.image} alt="Currency" />
      </div>
      <div className={styles.details}>
        <div className={styles.line}>
          <div className={cn('panel-title', styles.title)}>
            {marketItem.title}
          </div>
          <div
            className={
              marketPriceData.percentage >= 0
                ? styles.positive
                : styles.negative
            }
          >
            {parseFloat(marketPriceData?.percentage?.$numberDecimal || marketPriceData.percentage).toFixed(2)}%
          </div>
        </div>
        <div className={styles.price}>$ {parseFloat(marketPriceData?.currentPrice?.$numberDecimal || marketPriceData.currentPrice)}</div>
        {/* <div className={styles.money}>{marketPriceData.close}</div> */}
      </div>
    </>
  )
}

const Cards = ({ className }) => {

  useEffect(() => {
    Aos.init({ duration: 2000 })
  }, [])

  return (
    <div className={cn(className, styles.cards)}>
      {items.map((x, index) => (
        <Link data-aos="fade-up" data-aos-delay={index * 300} className={`${styles.card} ${styles2.card} `} key={index} to={x.url}>
          <MarketCard marketItem={x} />
        </Link>
      ))}
    </div>
  )
}

export default Cards
