import React from 'react'
import { Link } from 'react-router-dom'
import cn from 'classnames'
import styles from './Login.module.sass'
import { useTranslation } from 'react-i18next'
import { clientName } from 'content'
import useDarkMode from 'use-dark-mode'

const Login = ({ className, content, linkText, linkUrl, children }) => {

  const darkMode = useDarkMode();

  const { t } = useTranslation()
  return (
    <div className={cn(className, styles.login)}>
      <div
        className={styles.col}
        style={{
          backgroundImage: 'url(/images/content/bg-login.png)',
          paddingTop: '70px',
        }}
      >
        <Link style={{margin: '0 20px'}} className={"styles.logo"} to="/">
        <img
          src={darkMode.value ?
             "/static/img/logo.png"
              :
             "/static/img/logo.png"}
          alt="logo"
          height={50}
        />
           </Link>
      </div>
      <div className={styles.col}>
        <div className={styles.head}>
          <span>{t(content)}</span>
          <Link className={styles.link} to={linkUrl}>
            {t(linkText)}
          </Link>
        </div>
        <div className={styles.wrap}>{children}</div>
      </div>
    </div>
  )
}

export default Login
