import React from 'react'
import { useEffect, useContext } from 'react'
import { useSelector } from 'react-redux'
import TVChartContainer from './TVChartContainer'
import SocketContext from 'context/context'

export default function TradingViewChart(props) {
  const { selectedMarket } = props
  const { state, setState } = useContext(SocketContext)
  const { klines, loading } = useSelector((state) => state.crypto.klines)

  useEffect(() => {
    setState({
      ...state,
      klines: {
        symbol: selectedMarket,
        data: [],
      },
    })
  }, [selectedMarket])
  return (
    <TVChartContainer
      klines={klines}
      socketKlines={state}
      symbol={selectedMarket || 'ETH-USDT'}
    />
  )
}
