import { useFormik } from 'formik'
import React, { useState } from 'react'
import Form from './Form'

const Limit = ({
  contentBuy,
  contentSell,
  visible,
  setValue,
  selectedMarket,
}) => {
  const limitForm = useFormik({
    initialValues: {
      price: 0,
      amount: 0,
    },
    onSubmit: async (values) => {
      console.log('BuyBtcValues', values)
    },
  })

  return (
    <form>
      <Form
        contentBuy={contentBuy}
        contentSell={contentSell}
        visible={visible}
        setValue={setValue}
        type="limit"
        selectedMarket={selectedMarket}
      />
    </form>
  )
}

export default Limit
