import StandardLayout from './StandardLayout';
import AdvancedLeftLayout from './AdvancedLeftLayout';
import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';

const Exchange = ({ children, ...props }) => {
  const { selectedLayout } = useSelector((state) => state?.crypto?.layoutReducer);
  const [isSmallScreen, setIsSmallScreen] = useState(false);

  // Check screen size on mount and resize
  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth <= 768); // Adjust breakpoint as needed
    };

    handleResize(); // Initial check
    window.addEventListener('resize', handleResize); // Listen for resize events

    return () => {
      window.removeEventListener('resize', handleResize); // Cleanup
    };
  }, []);

  // Render AdvancedLeftLayout on small screens
  if (isSmallScreen) {
    return <AdvancedLeftLayout {...props}>{children}</AdvancedLeftLayout>;
  }

  // Render selected layout on larger screens
  return selectedLayout === 'Standard' ? (
    <StandardLayout {...props}>{children}</StandardLayout>
  ) : (
    <AdvancedLeftLayout {...props}>{children}</AdvancedLeftLayout>
  );
};

export default Exchange;