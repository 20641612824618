import React from 'react'
import Main from './Main'
import Details from './Details'
import Lessons from '../../components/Lessons'

const Market = () => {
  return (
    <>
      <Main />
      <Details />
      {/* <Lessons classSection="section" /> */}
    </>
  )
}

export default Market
