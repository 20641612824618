import React, { useState, useEffect, memo } from 'react';
import cn from 'classnames';
import styles from './Currency.module.sass';
import Icon from '../../../components/Icon';
import { FaStar } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';
import { usePricingMonitor } from 'hooks/usePricingMonitor';
import { useSelector } from 'react-redux';

const navigation = ['All', 'Favorite', 'Top'];

// Function to handle adding/removing favorites in local storage
const handleFavorite = (_id) => {
  const authUser = JSON.parse(localStorage.getItem('authUser'));
  const key = authUser?.email ? `favorites_${authUser.email}` : 'favorites';
  const favorites = JSON.parse(localStorage.getItem(key)) || [];
  const isFavorite = favorites.includes(_id);

  if (isFavorite) {
    const updatedFavorites = favorites.filter((id) => id !== _id);
    localStorage.setItem(key, JSON.stringify(updatedFavorites));
  } else {
    favorites.push(_id);
    localStorage.setItem(key, JSON.stringify(favorites));
  }
};

// Function to retrieve favorites from local storage
const getFavorites = () => {
  const authUser = JSON.parse(localStorage.getItem('authUser'));
  const key = authUser?.email ? `favorites_${authUser.email}` : 'favorites';
  return JSON.parse(localStorage.getItem(key)) || [];
};

const CurrencyRow = ({ data: x, onMarketSelect, onFavoriteToggle }) => {
  const pricingData = usePricingMonitor(x.instanceId);
  const [isFavorite, setIsFavorite] = useState(false);

  useEffect(() => {
    const favorites = getFavorites();
    setIsFavorite(favorites.includes(x._id));
  }, [x._id]);

  const toggleFavorite = () => {
    handleFavorite(x._id);
    setIsFavorite(!isFavorite);
    // Notify parent component that favorites have changed
    onFavoriteToggle();
  };

  return (
    <div
      className={styles.row}
      style={{ cursor: 'pointer' }}
      onClick={() => onMarketSelect(x.instanceId)}
    >
      <div className={styles.col}>
        <div className={styles.line}>
          <button
            className={cn('favorite', styles.favorite)}
            onClick={(e) => {
              e.stopPropagation();
              toggleFavorite();
            }}
          >
            {isFavorite ? <FaStar /> : <Icon name="star-outline" size="16" />}
          </button>
          <div className={styles.info}>{x.instanceId}</div>
        </div>
      </div>
      <div className={styles.col}>
        {parseFloat(
          pricingData?.currentPrice?.$numberDecimal ?? pricingData.currentPrice
        ).toFixed(2)}
      </div>
    </div>
  );
};

const Currency = ({ onMarketSelect }) => {
  const { t } = useTranslation();
  const [activeIndex, setActiveIndex] = useState(0);
  const [search, setSearch] = useState('');
  const [favoritesData, setFavoritesData] = useState([]);

  const markets = useSelector((state) => state.crypto.markets.markets);

  // Update favorites based on local storage
  const updateFavorites = () => {
    const favorites = getFavorites();
    const favoriteMarkets = markets.filter((market) => favorites.includes(market._id));
    setFavoritesData(favoriteMarkets);
  };

  // Update favorites every time the markets change or a favorite is toggled
  useEffect(() => {
    updateFavorites();
  }, [markets]);

  // Filter markets based on the active tab and search term
  const filteredMarkets = markets
    .filter((market) => {
      const matchesSearch = market.pairName
        ?.toLowerCase()
        .includes(search.replace(/-/g, '/').toLowerCase());
      if (activeIndex === 0 || activeIndex === 2) {
        // All and Top: show all markets (filtered by search)
        return matchesSearch;
      }
      if (activeIndex === 1) {
        // Favorite: show only markets in favorites that match the search
        const isFav = favoritesData.some((fav) => fav._id === market._id);
        return isFav && matchesSearch;
      }
      return false;
    })
    .sort((a, b) => {
      if (activeIndex === 2) {
        // Top: sort by price descending
        const priceA = parseFloat(a.currentPrice?.$numberDecimal || a.currentPrice || 0);
        const priceB = parseFloat(b.currentPrice?.$numberDecimal || b.currentPrice || 0);
        return priceB - priceA;
      }
      return 0;
    });

  return (
    <div className={styles.currency}>
      <div className={styles.nav}>
        {navigation.map((item, index) => (
          <button
            className={cn(styles.link, { [styles.active]: index === activeIndex })}
            onClick={() => setActiveIndex(index)}
            key={index}
          >
            {t(item)}
          </button>
        ))}
      </div>
      <div>
        <input
          className={styles.input}
          type="text"
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          name="search"
          placeholder="Search"
          required
        />
      </div>
      <div className={styles.table}>
        <div className={styles.row}>
          <div className={styles.col}>
            <div className="sorting">Pair</div>
          </div>
          <div className={styles.col}>
            <div className="sorting">Price</div>
          </div>
        </div>
        {filteredMarkets.map((x, index) => (
          <CurrencyRow
            key={index}
            data={x}
            onMarketSelect={onMarketSelect}
            onFavoriteToggle={updateFavorites}
          />
        ))}
      </div>
    </div>
  );
};

export default memo(Currency);
