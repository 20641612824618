/* eslint-disable react/jsx-indent-props */
/* eslint-disable react/no-unescaped-entities */
// import React, { useEffect } from 'react';
// import styles from './HomeSectionTwo.module.css';
// import Aos from 'aos';
// import 'aos/dist/aos.css';

// export default function HomeSectionTwo() {

//     useEffect(() => {
//         Aos.init({ duration: 800 });
//     }, []);

//   return (
//     <div
//       className={styles.services}
//       style={{ backgroundColor: '#141416' }}
//     >
//       <div className={styles.container}>
//         <div className={styles.grid}>
//           {/* Left Section */}
//           <div className={styles.gridItem}>
//             <div className={styles.servicesMain} data-aos="fade-up" data-aos-duration="800">
//               {/* Service Box 1 */}
//               <div
//                 className={styles.servicesBox}
//                 style={{ backgroundColor: '#f7f7f7' }}
//               >
                // <div
                //   className={styles.icon}
                //   style={{ backgroundColor: '#d335351a' }}
                // >
                //   <img
                //     src="/img/portfolio_mngr.svg"
                //     alt=""
                //     height="38"
                //     width="37"
                //   />
                // </div>
//                 <a href="/" className={`${styles.title} ${styles.defHvr}`}>
//                   Portfolio Manager
//                 </a>
//                 <p className={styles.text}>
//                   Buy and sell popular digital currencies, keep track of them in
//                   the one place.
//                 </p>
//               </div>
//               {/* Service Box 2 */}
//               <div
//                 className={styles.servicesBox}
//                 style={{ backgroundColor: '#f7f7f7' }}
//                 data-aos="fade-up"
//                 data-aos-duration="1000"
//               >
                // <div
                //   className={styles.icon}
                //   style={{ backgroundColor: '#58bd7d1a' }}
                // >
                //   <img
                //     src="/img/vaulutprotction.svg"
                //     alt=""
                //     height="38"
                //     width="37"
                //   />
                // </div>
//                 <a href="/" className={`${styles.title} ${styles.defHvr}`}>
//                   Vault protection
//                 </a>
//                 <p className={styles.text}>
//                   For Added Security, Store Your Funds In A Vault With Time
//                   Delayed Withdrawals.
//                 </p>
//               </div>
//               {/* Service Box 3 */}
//               <div
//                 className={styles.servicesBox}
//                 style={{ backgroundColor: '#f7f7f7' }}
//                 data-aos="fade-up"
//                 data-aos-duration="1200"
//               >
                // <div
                //   className={styles.icon}
                //   style={{ backgroundColor: '#3772ff1a' }}
                // >
                //   <img
                //     src="/img/mobleapp.svg"
                //     alt=""
                //     height="38"
                //     width="37"
                //   />
                // </div>
//                 <a href="/" className={`${styles.title} ${styles.defHvr}`}>
//                   Mobile Apps
//                 </a>
//                 <p className={styles.text}>
//                   Stay On Top Of The Markets With The Cryptolly App For Android
//                   Or IOS.
//                 </p>
//               </div>
//             </div>
//           </div>
//           {/* Right Section */}
//           <div className={styles.gridItem}>
//             <div className={styles.blockText} data-aos="fade-up">
//               <h3 className={styles.heading}>
//                 The most trusted cryptocurrency platform.
//               </h3>
//               <p className={styles.desc}>
//                 Cryptolly has a variety of features that make it the best place
//                 to start trading
//               </p>
//               <a href="/" className={styles.btnAction}>
//                 Let’s Trade Now
//               </a>
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// }


import React, { useEffect } from "react";
import styles from "./HomeSectionTwo.module.css";
import useDarkMode from "use-dark-mode";
import Aos from 'aos';
import 'aos/dist/aos.css';

export default function HomeSectionTwo() {

    useEffect(() => {
        Aos.init({ duration: 2000 });
      }, []);

    const colorMode = useDarkMode();

  return (
    <div className={`${colorMode.value ? styles.dark : ''} ${styles.featuresContainer}`}>
        <div className={styles.left}>
        <div data-aos="fade-up" className={styles.feature}>
        <div
                  className={styles.icon}
                  style={{ backgroundColor: '#d335351a' }}
                >
                  <img
                    src="/img/portfolio_mngr.svg"
                    alt=""
                    height="38"
                    width="37"
                  />
                </div>
        <h2>Portfolio Manager</h2>
        <p>Buy And Sell Popular Digital Currencies, Keep Track Of Them In The One Place.</p>
      </div>
      <div data-aos="fade-up" data-aos-delay="200" className={styles.feature}>
      <div
                  className={styles.icon}
                  style={{ backgroundColor: '#3772ff1a' }}
                >
                  <img
                    src="/img/mobleapp.svg"
                    alt=""
                    height="38"
                    width="37"
                  />
                </div>
        <h2>Mobile Apps</h2>
        <p>Stay On Top Of The Markets With The Cryptolly App For Android Or IOS.</p>
      </div>
        </div>
        <div className={styles.right}>
            <div data-aos="fade-up" data-aos-delay="400" className={styles.feature}>
            <div
                  className={styles.icon}
                  style={{ backgroundColor: '#58bd7d1a' }}
                >
                  <img
                    src="/img/vaulutprotction.svg"
                    alt=""
                    height="38"
                    width="37"
                  />
                </div>
        <h2>Vault Protection</h2>
        <p>For Added Security, Store Your Funds In A Vault. With Time Delayed Withdrawals.</p>
      </div>
        </div>
      
      <div data-aos="fade-up" className={styles.cta}>
        <h1>The Most Trusted Cryptocurrency Platform.</h1>
        <p>Cryptolly has a variety of features that make it the best place to start trading.</p>
        <button className={styles.tradeButton}>Let's Trade Now</button>
      </div>
    </div>
  );
};