// import logo from 'assets/images/brands/brand-main-sm.png'
// import darkLogo from 'assets/images/brands/darkLogo.png'

export const clientName = 'Waltica'
export const developedBy = 'FiniticX'
export const imagesUrl = process.env.REACT_APP_API_IMAGES_URL
export const cpUrl = process.env.REACT_APP_CP_URL
export const apiUrl =
  process.env.REACT_APP_API_CP_DOMAIN || 'http://localhost:3001'
export const companyName = 'Waltica'
export const companyWebsite = ''
export const privacyPolicyLink = ''
export const clientAgreementLink = ''
// export const sidebarLogo = logo
// export const smLogo = logo
// export const mainLogo = logo
// export const darkMainLogo = darkLogo
