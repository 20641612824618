import React, { useEffect } from 'react'
import cn from 'classnames'
import styles from './Main.module.sass'
import { useTranslation } from 'react-i18next'
import Aos from 'aos'
import 'aos/dist/aos.css'

const Main = () => {
  const { t } = useTranslation()

  useEffect(() => {
    Aos.init({ duration: 2000 })
  }, [])

  return (
    <div className={cn('section', styles.main)}>
      <div className={cn('container', styles.container)}>
        <div data-aos="fade-right" className={styles.wrap}>
          <h1 className={cn('h1', styles.title)}>
            {t('Today’s Cryptocurrency prices')}
          </h1>
          <div className={styles.text}>
            {t('The global crypto market cap is')} <strong>$1.86T</strong>
          </div>
        </div>
        <div data-aos="fade-left" className={cn('bg', styles.bg)}>
          <img
            // srcSet="/public/img/market_main.png"
            src="/img/market_main.png"
            alt="Market main"
          />
        </div>
      </div>
    </div>
  )
}

export default Main
