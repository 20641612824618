import React from 'react'
import cn from 'classnames'
import styles from './Details.module.sass'
import Panel from './Panel'
import Trade from './Trade'
import Trend from '../../Home/Trend'
import Cards from 'screens/Home/Main/Cards'

const Details = () => {
  return (
    <div className={cn('section', styles.details)}>
      <div className={cn('container', styles.container)}>
        {/* <Panel /> */}
        <div style={{ margin: '40px 0' }}>
        <Cards />
        <Trend />
        </div>
        {/* <Trade /> */}
      </div>
    </div>
  )
}

export default Details
