import React, { useMemo, useState } from 'react'
import cn from 'classnames'
import styles from './SelectCrypto.module.sass'
import Icon from '../Icon'
import { useSelector } from 'react-redux'

const items = [
  {
    title: 'Bitcoin',
    currency: 'BTC-USDT',
    image: '/images/content/crypto/BTC.svg',
  },
  {
    title: 'Ethereum',
    currency: 'ETH-USDT',
    image: '/images/content/crypto/ETH.svg',
  },
  {
    title: 'TRX',
    currency: 'TRX-USDT',
    image: '/images/content/crypto/TRX.svg',
  },
  {
    title: 'LTC',
    currency: 'LTC-USDT',
    image: '/images/content/crypto/LTC.svg',
  },
  {
    title: 'MATIC',
    currency: 'MATIC-USDT',
    image: '/images/content/crypto/MATIC.svg',
  },
  {
    title: 'Dogecoin',
    currency: 'DOGE-USDT',
    image: '/images/content/crypto/DOGE.svg',
  },
  {
    title: 'XRP',
    currency: 'XRP-USDT',
    image: '/images/content/crypto/XRP.svg',
  },
  {
    title: 'SHIB',
    currency: 'SHIB-USDT',
    image: '/images/content/crypto/SHIB.svg',
  },
  {
    title: 'LINK',
    currency: 'LINK-USDT',
    image: '/images/content/crypto/LINK.svg',
  },
  {
    title: 'BNB',
    currency: 'BNB-USDT',
    image: '/images/content/crypto/BNB.svg',
  },
]

const MarketCard = ({ marketItem }) => {
  const markets = useSelector((state) => state?.crypto.markets.markets)
  const marketPriceData = useMemo(() => {
    const data = markets?.filter((x) => x.instanceId === marketItem.currency)[0]
    if (!data) return { marketPrice: '0.00', percentage: '0.00', close: '0.00' }

    return {
      marketPrice: data?.marketPrice?.$numberDecimal || data?.close,
      percentage: data?.percentage.$numberDecimal,
      close: data?.close?.$numberDecimal,
    }
  }, [markets])

  return (
    <>
      <div className={styles.col}>
        <div className={styles.item}>
          <div className={cn('currency-icon', styles.icon)}>
            <img src={marketItem.image} alt="Currency" />
          </div>
          <div className={styles.details}>
            <span className={styles.subtitle}>{marketItem.title}</span>
          </div>
        </div>
      </div>
      <div className={styles.col}>
        <div>${marketPriceData.marketPrice}</div>
      </div>
      <div className={styles.col}>
        <div
          className={
            marketPriceData.percentage >= 0 ? styles.positive : styles.negative
          }
        >
          {marketPriceData.percentage}
        </div>
      </div>
    </>
  )
}

const SelectCrypto = ({ goNext }) => {
  const [search, setSearch] = useState('')
  // const marketPriceData = useMemo(() => {
  //   const data = markets?.filter((x) => x.pairName === marketItem.currency)[0]
  //   if (!data) return { marketPrice: '0.00', percentage: '0.00', close: '0.00' }

  //   return {
  //     marketPrice: data?.marketPrice?.$numberDecimal,
  //     percentage: data?.percentage,
  //     close: data?.close?.$numberDecimal,
  //   }
  // }, [markets])


  const handleSubmit = (e) => {
    alert()
  }

  return (
    <div className={styles.crypto}>
      <div className={styles.title}>Select crypto</div>
      <form className={styles.form} action="" onSubmit={() => handleSubmit()}>
        <input
          className={styles.input}
          type="text"
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          name="search"
          placeholder="Search coin"
          required
        />
        <button className={styles.result}>
          <Icon name="search" size="20" />
        </button>
      </form>
      <div className={styles.table}>
        <div className={styles.row}>
          <div className={styles.col}>
            <div className="sorting">#</div>
          </div>
          <div className={styles.col}>
            <div className="sorting">Name</div>
          </div>
          <div className={styles.col}>
            <div className="sorting">Price</div>
          </div>
          <div className={styles.col}>24h %</div>
        </div>
        {items.map((x, index) => (
          <div className={styles.row} key={index} onClick={goNext}>
            <div className={styles.col}>
              <div className={styles.line}>
                {/* <button className={cn('favorite', styles.favorite)}>
                  <Icon name="star-outline" size="16" />
                </button> */}
                {index + 1}
              </div>
            </div>
            {/* <div className={styles.col}>
              <div className={styles.item}>
                <div className={styles.icon}>
                  <img src={x.image} alt="Coin" />
                </div>
                <div className={styles.details}>
                  <span className={styles.subtitle}>{x.title}</span>
                  <span className={styles.currency}>{x.currency}</span>
                </div>
              </div>
            </div> */}
            <MarketCard marketItem={x} />
            {/* <div className={styles.col}>
              {x.positiveDay && (
                <div className={styles.positive}>{x.positiveDay}</div>
              )}
              {x.negativeDay && (
                <div className={styles.negative}>{x.negativeDay}</div>
              )}
            </div> */}
          </div>
        ))}
      </div>
    </div>
  )
}

export default SelectCrypto
