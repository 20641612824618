import { useRef } from 'react'
import Main from './Main'
import Trend from './Trend'
import Popular from '../../components/Popular'
import Download from './Download'
import News from '../../components/News'
import Steps from './Steps'
import Banners from './Banners'
import EarnLikeBanner from 'screens/Reward/Affiliate/EarBanner'
import GrabDemoAccount from './GrabDemoAccount'
import Instruments from 'components/Instruments'
import StockTabs from 'components/StockTab'
import Liquidity from 'components/Liquity'
import BannerSections from 'components/BannerSections/BannerSections'
import HomeSectionTwo from 'components/HomeSections/HomeSectionTwo'
import Learn from './Trend/index';
import TradeWithCrypto from './CryptoBanner'
import HomeSectionFour from 'components/HomeSections/HomeSectionFour'
import HomeSectionFive from 'components/HomeSections/HomeSectionFive'
import HomeSectionSix from 'components/HomeSections/HomeSectionsix'
import Cards from './Main/Cards'

const Home = () => {
  const scrollToRef = useRef(null)

  return (
    <>
    
    <div className="container">
    <BannerSections />
    <Cards />
    <HomeSectionTwo />
    </div>
      {/* <Main scrollToRef={scrollToRef} /> */}
      {/* <Banners /> */}
      
      <Trend />
      <div className="container">
      <HomeSectionFour />
      <HomeSectionFive />
      </div>
      
      {/* <HomeSectionSix /> */}
      {/* <Instruments /> */}
      {/* <Popular classSection="section-bg section-mb0" /> */}
      {/* <StockTabs /> */}
      {/* <EarnLikeBanner /> */}
      {/* <RestAssured /> */}
      {/* <TradeWithCrypto /> */}
      {/* <Steps /> */}
      {/* <Download /> */}
      <div className="container">
        {/* <GrabDemoAccount /> */}
      </div>
      {/* <News classSection="section-bg" /> */}
      {/* <Liquidity /> */}
    </>
  )
}

export default Home
