// import { socket } from './index'

// const updateMarkets = (currentMarkets, newData) => {
//   newData?.forEach((market) => {
//     const exists = currentMarkets.find((x) => x.instanceId === market.instanceId)
//     if (!exists) currentMarkets.push(market)
//   })
//   let updatedData = currentMarkets
//   updatedData = updatedData.map((market) => {
//     const found = newData.find((x) => x.instanceId === market.instanceId)
//     return {
//       ...market,
//       ...found,
//     }
//   })
//   return updatedData
// }

// const updateKlines = (currentKlines, newKline) => {
//   if (currentKlines.symbol !== newKline.symbol) return currentKlines
//   currentKlines.data.push(newKline)
//   return currentKlines
// }

// const updateAllKlines = (currentAllKlines, newKline) => {
//   let currentAllKlinesData = { ...currentAllKlines }
//   const exists = currentAllKlinesData[`${newKline.symbol}`]
//   if (!exists) currentAllKlinesData[`${newKline.symbol}`] = []
//   let currentAllKlinesDataArray = [
//     ...currentAllKlinesData[`${newKline.symbol}`],
//   ]

//   currentAllKlinesDataArray?.reverse()

//   if (currentAllKlinesDataArray?.length >= 20) {
//     currentAllKlinesDataArray.shift()
//     currentAllKlinesDataArray?.push(newKline)
//   } else {
//     currentAllKlinesDataArray?.push(newKline)
//   }
//   currentAllKlinesDataArray?.reverse()
//   currentAllKlinesData[`${newKline.symbol}`] = currentAllKlinesDataArray
//   return currentAllKlinesData
// }

// const updateOrderBooks = (currentOrderBooks, newData) => {
//   const exists = currentOrderBooks.find((x) => x.instanceId === newData.instanceId)
//   if (!exists) currentOrderBooks.push(newData)
//   let updatedData = currentOrderBooks
//   updatedData = updatedData.map((ob) => {
//     const found = newData.instanceId === ob.instanceId
//     if (found) {
//       return {
//         ...ob,
//         ...newData,
//       }
//     } else {
//       return ob
//     }
//   })

//   return updatedData
// }

// const updateTrades = (currentTrades, newData) => {
//   let currectTradesData = { ...currentTrades }
//   const exists = currectTradesData[`${newData.symbol}`]
//   if (!exists) currectTradesData[`${newData.symbol}`] = []
//   let currectTradesDataArray = [...currectTradesData[`${newData.symbol}`]]

//   currectTradesDataArray?.reverse()

//   if (currectTradesDataArray?.length >= 20) {
//     currectTradesDataArray.shift()
//     currectTradesDataArray?.push(newData)
//   } else {
//     currectTradesDataArray?.push(newData)
//   }
//   currectTradesDataArray?.reverse()
//   currectTradesData[`${newData.symbol}`] = currectTradesDataArray
//   return currectTradesData
// }


// export const updateAllTrades = (currentTrades, newData = []) => {
//   let currectTradesData = { ...currentTrades }
//   newData?.forEach(item=>{
//     const exists = currectTradesData[`${item.symbol}`]
//     if (!exists) currectTradesData[`${item.symbol}`] = []
//     let currectTradesDataArray = [...currectTradesData[`${item.symbol}`]]
    
//     if (currectTradesDataArray?.length <= 20){
//       currectTradesDataArray?.push(item)
//     }
    
//     currectTradesDataArray?.sort((a, b)=> b?.timestamp - a?.timestamp)
//     currectTradesData[`${item.symbol}`] = currectTradesDataArray
//   })
//   return currectTradesData
// }

// export const socketEvents = ({ state, setState }) => {
//   console.log("socketEvents", socketEvents)
//   if (state.markupId) {
//     socket.on('pricing_64395106ffb0ac62511116c7', () => {})
//   }
//   const pricingEvent = 'pricing'
//   socket.on(pricingEvent, (msg) => {
//     console.log("pricingEvent", pricingEvent)
//     setState((state) => {
//       const message = JSON.parse(msg)
//       const markets = updateMarkets(state.markets, message)
//       return {
//         ...state,
//         markets,
//       }
//     })
//   })
//   const klineEvent = state.markupId ? `kline_${state.markupId}` : 'kline'

//   socket.on(klineEvent, (msg) => {
//     console.log("klineEvent", klineEvent)
//     setState((state) => {
//       const message = JSON.parse(msg)
//       // chart accepts kline times in secs
//       message.time /= 1000
//       const klines = updateKlines(state.klines, message)
//       const allKlines = updateAllKlines(state.allKlines, message)
//       return {
//         ...state,
//         klines,
//         allKlines,
//       }
//     })
//   })

//   socket.on('orderbook', (msg) => {
//     console.log("orderbook")
//     setState((state) => {
//       const message = JSON.parse(msg)
//       const orderBooks = updateOrderBooks(state.orderBooks, message)
//       return {
//         ...state,
//         orderBooks,
//       }
//     })
//   })
//   socket.on('trade', (msg) => {
//   console.log("trade", trade)
//     setState((state) => {
//       const message = JSON.parse(msg)
//       const trades = updateTrades(state.trades, message)
//       return {
//         ...state,
//         trades,
//       }
//     })
//   })
// }

// socket/events.js
import { socket } from './index'

const updateMarkets = (currentMarkets, newData) => {
  if (!Array.isArray(newData)) return currentMarkets
  
  const updatedMarkets = [...currentMarkets]
  
  newData.forEach((market) => {
    const existingIndex = updatedMarkets.findIndex(x => x.instanceId === market.instanceId)
    if (existingIndex === -1) {
      updatedMarkets.push(market)
    } else {
      updatedMarkets[existingIndex] = {
        ...updatedMarkets[existingIndex],
        ...market
      }
    }
  })
  
  return updatedMarkets
}

const updateKlines = (currentKlines, newKline) => {
  if (currentKlines.symbol !== newKline.symbol) return currentKlines
  
  return {
    ...currentKlines,
    data: [...currentKlines.data, newKline]
  }
}

const updateAllKlines = (currentAllKlines, newKline) => {
  const symbol = newKline.symbol
  const currentSymbolKlines = currentAllKlines[symbol] || []
  
  let updatedKlines = [...currentSymbolKlines]
  updatedKlines.reverse()
  
  if (updatedKlines.length >= 20) {
    updatedKlines = updatedKlines.slice(0, 19)
  }
  updatedKlines.push(newKline)
  updatedKlines.reverse()
  
  return {
    ...currentAllKlines,
    [symbol]: updatedKlines
  }
}

const updateOrderBooks = (currentOrderBooks, newData) => {
  const updatedBooks = [...currentOrderBooks]
  const existingIndex = updatedBooks.findIndex(x => x.instanceId === newData.instanceId)
  
  if (existingIndex === -1) {
    updatedBooks.push(newData)
  } else {
    updatedBooks[existingIndex] = {
      ...updatedBooks[existingIndex],
      ...newData
    }
  }
  
  return updatedBooks
}

const updateTrades = (currentTrades, newData) => {
  const symbol = newData.symbol
  const currentSymbolTrades = currentTrades[symbol] || []
  
  let updatedTrades = [...currentSymbolTrades]
  updatedTrades.reverse()
  
  if (updatedTrades.length >= 20) {
    updatedTrades = updatedTrades.slice(0, 19)
  }
  updatedTrades.push(newData)
  updatedTrades.reverse()
  
  return {
    ...currentTrades,
    [symbol]: updatedTrades
  }
}

export const updateAllTrades = (currentTrades, newData = []) => {
  const updatedTrades = { ...currentTrades }
  
  newData.forEach(trade => {
    const symbol = trade.symbol
    const currentSymbolTrades = updatedTrades[symbol] || []
    
    if (currentSymbolTrades.length < 20) {
      currentSymbolTrades.push(trade)
      currentSymbolTrades.sort((a, b) => b.timestamp - a.timestamp)
      updatedTrades[symbol] = currentSymbolTrades
    }
  })
  
  return updatedTrades
}

export const setupEventHandlers = ({ state, setState }) => {
  // Handle pricing updates
  if (state.markupId) {
    const pricingEvent = `pricing_${state.markupId}`
    socket.on(pricingEvent, (data) => {
      const message = typeof data === 'string' ? JSON.parse(data) : data
      setState(prev => ({
        ...prev,
        markets: updateMarkets(prev.markets, message)
      }))
    })
  }

  // Handle kline updates
  const klineEvent = state.markupId ? `kline_${state.markupId}` : 'kline'
  socket.on(klineEvent, (data) => {
    const message = typeof data === 'string' ? JSON.parse(data) : data
    message.time = message.time / 1000 // Convert to seconds for chart
    
    setState(prev => ({
      ...prev,
      klines: updateKlines(prev.klines, message),
      allKlines: updateAllKlines(prev.allKlines, message)
    }))
  })

  // Handle orderbook updates
  socket.on('orderbook', (data) => {
    const message = typeof data === 'string' ? JSON.parse(data) : data
    setState(prev => ({
      ...prev,
      orderBooks: updateOrderBooks(prev.orderBooks, message)
    }))
  })

  // Handle trade updates
  socket.on('trade', (data) => {
    const message = typeof data === 'string' ? JSON.parse(data) : data
    setState(prev => ({
      ...prev,
      trades: updateTrades(prev.trades, message)
    }))
  })
}