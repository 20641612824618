import React, { useEffect, useState } from 'react'
import cn from 'classnames'
import styles from './Convert.module.sass'
import Icon from '../Icon'
import TextInput from '../TextInput'
import Successfully from './Successfully'
import { useDispatch, useSelector } from 'react-redux'
import DropdownCoins from 'components/DropdownCoins'
import { toast } from 'react-toastify'
import {
  convertStart, previewConversion as previewConversionAction, previewConversionSuccess, resetConvert 
} from "store/crypto/convert/actions";
import { convertAPI, previewConversionAPI } from 'apis/convert'


const Convert = (props) => {
  const [responseData, setResponseData] = useState([])
  const [walletsOptions, setWalletsOptions] = useState([])
  const [visibleConvert, setVisibleConvert] = useState(true)
  const [visibleSuccessfully, setVisibleSuccessfully] = useState(false)
  const [coin, setCoin] = useState('')
  const [coinTo, setCoinTo] = useState('')
  const [amount, setAmount] = useState()
  const [fees, setFees] = useState(0)
  const [Convertamount, setConvertmount] = useState()
  const [timer, setTimer] = React.useState(10);
  const [responseReview, setResponseReview ] = useState([])
  const id = React.useRef(null);

  const { wallets, allWallets } = useSelector((state) => state?.crypto?.wallets)
  const convertState = useSelector(state=>state.crypto.convert);
  const { previewConversion } = useSelector(state=>state.crypto.convert);

  const clear = ()=>{
    window.clearInterval(id.current);
  };
  React.useEffect(()=>{
    if (convertState.step === "convert")
      id.current = window.setInterval(()=>{
        setTimer((time)=>time - 1);
      }, 1000);
    return ()=> clear();
  }, [convertState.step]);


  useEffect(()=>{
    return ()=> {
      clear();
      setTimer(10);
      dispatch(resetConvert());
    };
  }, []);

  const dispatch = useDispatch()
  // useEffect(()=>{
  //   if (coin && coinTo && coin !== "" && coinTo !== "" && Convertamount !== "" && Convertamount > 0) {
  //     dispatch(previewConversionAction({
  //       fromAsset: coin.asset,
  //       toAsset: coinTo.asset,
  //     }));
  //   }
  // }, [coinTo, coin, Convertamount]);

  const sendPreview = async ()=>{
    if (coin && coinTo && coin !== "" && coinTo !== "") {
      // dispatch(previewConversionAction({
      //   fromAsset: coin.asset,
      //   toAsset: coinTo.asset,
      // }));
      try {
        const sendData =  {  
          fromAsset: coin.asset,
          toAsset: coinTo.asset,
        }
  
      let data = await previewConversionAPI(  sendData  )
      console.log("dataaaassss", data);
      setResponseReview(data?.result)
      dispatch(previewConversionAction( sendData ));
      if (data?.isError){
        toast.error(data?.message);
    }
      } catch (error) {
        toast.error(error?.message) 
      }
    }
  }
  const refresh = async ()=>{
    if (coin && coinTo && coin !== "" && coinTo !== "") {
      resetTimer();
      // dispatch(previewConversionAction({
      //   fromAsset: coin.asset,
      //   toAsset: coinTo.asset,
      // }));

      try {
        const sendData =  {  
        fromAsset: coin.asset,
        toAsset: coinTo.asset,
      }

    let data = await previewConversionAPI(  sendData  )
    console.log("dataaaa", data);
    setResponseReview(data?.result)
    dispatch(previewConversionAction( sendData ));
    if (data?.isError){
      toast.error(data?.message);
    }
  } catch (error) {
      toast.error(error?.message) 
    }
    }
  }

  
  const convertStartSendData = async () => {
    try {
      const found = wallets.find((wallet) => wallet.asset === coin.asset);
      if (found === undefined || found?.amount <= 0) return;
      //  const res =  await  dispatch(convertStart({
      // toAsset: coin.asset,
      // fromAsset: coinTo.asset,
      // fromAssetId: coin.assetId._id,
      // toAssetId: coinTo.assetId._id, 
      // amount: Convertamount.toString(),
      // }))}
  
  
    if (found?.amount > 0){
  
      const sendData =  {  
        toAsset: coinTo.asset,
        fromAsset: coin.asset,
        fromAssetId: coin.assetId._id,
        toAssetId: coinTo.assetId._id, 
        amount: Convertamount.toString()
      }

    let data = await convertAPI(  sendData  )
    setResponseData(data?.result)
    if (data?.isSuccess) {
      setVisibleConvert(false)
      setVisibleSuccessfully(true)
    }
    if (data?.isError){
      toast.error(data?.message);
    }
  }
    } catch (error) {
      toast.error(error?.message) 
    }


}
  React.useEffect(()=>{
    if (timer == 0){
      clear();
    }
  }, [timer]);

  const resetTimer = ()=>{
    clear();
    setTimer(10);
    id.current = window.setInterval(()=>{
      setTimer((time)=>time - 1);
    }, 1000);
  };
  useEffect(()=>{
    setWalletsOptions(allWallets?.trading || [])
  }, [allWallets])
  useEffect(()=>{
    setAmount(coin?.amount)
  }, [coin])

  return (
    <>
      {visibleConvert && (
        <div className={styles.withdraw}>
          <div className={cn('h4', styles.title)}>
            <Icon name="arrow-left" size="32" />
            Convert 
          </div>
          <div>
            <label className={styles.label}>From Asset</label>
            <DropdownCoins
              className={styles.dropdown}
              value={coin}
              setValue={setCoin}
              options={walletsOptions}
            />
            {/* {selectCurrancyError && (
              <p className="small text-danger "> {props.t("Please Select Currency")}</p>
            )} */}
          </div>

          <div>
            <label className={styles.label}>To Asset</label>
            <DropdownCoins
              className={styles.dropdown}
              value={coinTo}
              setValue={setCoinTo}
              options={walletsOptions}
            />
            {/* {selectCurrancyError && (
              <p className="small text-danger "> {props.t("Please Select Currency")}</p>
            )} */}
          </div>
          {/* <Checkbox
            className={styles.checkbox}
            value={save}
            onChange={() => setSave(!save)}
            content="Save address for next time"
          /> */}
          <div className={styles.wrap}>
            <div className={styles.category}>
              Available <br></br>balance
            </div>
            <div className={styles.details}>
              <div className={styles.currency}>{amount} <br/> {coin.asset}</div>
              {/* <div className={styles.price}>$10,095.35</div> */}
            </div>
          </div>
          <div className={styles.box}>
            <TextInput
              className={styles.field}
              label="Amount to withdraw"
              name="amount"
              type="number"
              // note="$1,000,000.00 daily withdrawal limit remaining."
              onChange={(e)=> setConvertmount(e?.target?.value)}
              value={Convertamount}
              required
            />
            <button className={cn('button-stroke button-small', styles.button)} onClick={()=>{setConvertmount(amount || 0)}}>
              Max amount
            </button>
          </div>

          {previewConversion?.success && <>
            <div>
              <div>
                1 {coin?.asset} = Approx {(responseReview?.[`${coin?.asset}_${coinTo?.asset}`])?.toFixed(4)} {`${coinTo?.asset}`}
              </div>
              <div>
                1 {coinTo?.asset} = Approx {(responseReview?.[`${coinTo?.asset}_${coin?.asset}`])?.toFixed(4)} {`${coin?.asset}`}
              </div>
            </div>
          </>}

          {previewConversion?.success && <>
            <div style={{ display:'flex', justifyContent:'space-between'}}>
              <div>
              From
              </div>
              <div>
              {`${Convertamount}`} {`${coin?.asset}`}
              </div>
            </div>
            <div style={{ display:'flex', justifyContent:'space-between'}}>
              <div>
              Fees
              </div>
              <div>
              {`${fees}`} {`${coin?.asset}`}
              </div>
            </div>
            <div style={{ display:'flex', justifyContent:'space-between'}}>
              <div>
              From After Fees
              </div>
              <div>
              {`${Convertamount - fees}`} {`${coin?.asset}`}
              </div>
            </div>
            <div style={{ display:'flex', justifyContent:'space-between', marginBottom:'10px'}}>
              <div>
                To
              </div>
              <div>
              {`${Convertamount}` * (responseReview?.[`${coinTo?.asset}_${coin?.asset}`])}
              </div>
            </div>
            {/* {!coin && <div> 
              <div color="danger" className="mb-0 p-2">{t(`Insufficient ${coinTo.asset} at your wallet`)}</div>
            </div>} */}
          </>}
          {
            convertState?.step === "preview_conversion" && 
               <button
                 className={cn('button', styles.button)}
                 onClick={()=>
              sendPreview()             
              }>
              
             Preview Conversion
          </button>
          }


{convertState.step === "convert" &&  <div style={{ display:'flex', width:'100%' }}>

<button
  type="submit"
  className={cn('button', styles.button)}
  disabled={ convertState.loading || timer === 0 }
  style={{ width:'45%' }}
  onClick={()=>
    convertStartSendData()            
    }
 >
              
             Convert
          </button>


          <button
            style={{ width:'50%', marginLeft:'10px' }}
            className={cn('button', styles.button)}
            
            disabled={ convertState.loading || timer > 0 }
            onClick={()=>
              refresh()             
              }
 >
              
             Refresh {timer}
          </button>
</div>}

        </div>
      )}
      {visibleSuccessfully && <Successfully responseData={responseData}  setVisibleConvert={props.setVisibleConvert} setVisibleSuccessfully={setVisibleSuccessfully} onClose={props.onClose}/>}
    </>
  )
}

export default Convert
