import Login from 'components/Login'
import React from 'react'
import ResetPasswordForm from './Form'

export default function ResetPassword() {
  return (
    <Login
      content="Don’t have an account?"
      linkText="Sign up for free"
      linkUrl="/register/crypto/live"
    >
        <ResetPasswordForm />
    </Login>
  )
}
