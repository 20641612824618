/* eslint-disable no-console */
import React, { useState } from 'react';
import cn from 'classnames';
import styles from './ResetPassword.module.sass';
import TextInput from '../../../components/TextInput';
import { toast } from 'react-toastify';
import { useSearchParams, useNavigate } from 'react-router-dom';
import Loader from 'components/Loader';
import { resetpassword } from 'apis/resetPassword';

const ResetPasswordForm = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate(); 
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [loading, setLoading] = useState(false);

  async function handleValidSubmit(e) {
    e.preventDefault();
  
    // Check if passwords match
    if (confirmPassword !== password) {
      toast.error('Confirm Password Should Be Equal to Password');
      return;
    }
  
    // Prepare the payload
    const data = { token: searchParams?.get('token'), password };
    // console.log(data);
  
    setLoading(true);
    try {
      // Call the resetpassword API
      const res = await resetpassword(data);
      // console.log(res);
  
      // Check if the response indicates success
      if (res.status) {

        toast.success('Password reset successfully! Redirecting to login...');

        setTimeout(() => {
          navigate('/login');
        }, 2000);
      } else {
        
        toast.error(res.message || 'Failed to reset password. Please try again.');
      }
    } catch (error) {
      // console.error(error);
      toast.error('An error occurred. Please try again.');
    } finally {
      setLoading(false); 
    }
  }

  return (
    <form className={styles.form} onSubmit={handleValidSubmit}>
      <h3 className={cn('h3', styles.title)}>New password</h3>
      <div className={styles.fieldset}>
        <TextInput
          className={styles.field}
          label="new password"
          name="new-password"
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          placeholder="Password"
          required
          view
        />
        <TextInput
          className={styles.field}
          label="confirm password"
          name="confirm-password"
          type="password"
          placeholder="Password"
          value={confirmPassword}
          onChange={(e) => setConfirmPassword(e.target.value)}
          required
          view
        />
      </div>
      <button className={cn('button', styles.button)} disabled={loading}>
        {loading ? <Loader /> : 'Reset password'}
      </button>
    </form>
  );
};

export default ResetPasswordForm;
