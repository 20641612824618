import { takeEvery, put, call } from 'redux-saga/effects'
import {
  FETCH_DEPOSIT_WALLETS_START,
  // FETCH_WALLET_REPORT_START,
  // FETCH_WALLETS_START,
  // REQUEST_WALLET_FX_TRANSFER_START,
} from './actionTypes'
import {
  fetchDepositWalletsAPI,
  // createWalletTransferRequest,
  // fetchReportsAPI,
} from '../../../apis/htx'
import {
  // createWalletTransferError,
  // createWalletTransferSuccess,
  fetchDepositWalletsFailed,
  fetchDepositWalletsSuccess,
  // fetchReportFailed,
  // fetchReportSuccess,
  // fetchWalletsFailed,
  // fetchWalletsSuccess,
} from './actions'
import {
  showErrorNotification,
  showSuccessNotification,
} from 'store/general/notifications/actions'

function* fetchDepositWallets(params) {
  try {
    console.log("1111111111111", params)
    const result = yield call(fetchDepositWalletsAPI, params)
    console.log("resultttttttt", result);
    yield put(fetchDepositWalletsSuccess(result))
  } catch (error) {
    yield put(fetchDepositWalletsFailed(error))
  }
}

// function* createWalletRequest({ payload }) {
//   try {
//     const response = yield call(createWalletTransferRequest, { payload })
//     yield put(createWalletTransferSuccess(response))
//     yield put(showSuccessNotification('Transfer Request Sent Successfully'))
//   } catch (error) {
//     yield put(createWalletTransferError(error))
//     yield put(showErrorNotification(error?.message || 'Something went wrong'))
//   }
// }

// function* fetchReports({ payload }) {
//   try {
//     const result = yield call(fetchReportsAPI, payload)
//     yield put(fetchReportSuccess(result))
//   } catch (error) {
//     yield put(fetchReportFailed(error))
//   }
// }

function* WatchHTX() {
  yield takeEvery(FETCH_DEPOSIT_WALLETS_START, fetchDepositWallets)
  // yield takeEvery(REQUEST_WALLET_FX_TRANSFER_START, createWalletRequest)
  // yield takeEvery(FETCH_WALLET_REPORT_START, fetchReports)
}

export default WatchHTX
