// /* eslint-disable no-console */
// import React, { useState } from "react";
// import { Link } from "react-router-dom";
// import useDarkMode from "use-dark-mode";
// import styles from "./Header.module.css";
// import Icon from "components/Icon";
// import { useColorMode, useColorModeValue } from "@chakra-ui/react";

// const pages = [
//   {
//     title: "Liquidity",
//     url: "/",
//   },
//   {
//     title: "Technology",
//     url: "/",
//   },
//   {
//     title: "About Us",
//     url: "/",
//   },
//   {
//     title: "Newsroom",
//     url: "/",
//   },
//   {
//     title: "Legal Documents",
//     url: "/",
//   },
//   {
//     title: "Spot",
//     url: "/exchange",
//   },
//   {
//     title: "Affiliate",
//     url: "/reward/affiliate",
//   }
// ];

// const navigation = [
//   {
//     title: 'Trade',
//     dropdown: [
//       {
//         title: 'Spot',
//         icon: 'refresh',
//         url: '/exchange',
//         isIcon: true,
//       },
//       {
//         title: 'Futures',
//         icon: 'newspaper-folded',
//         url: '/exchange/futures',
//         isIcon: true,
//       },
//       {
//         title: 'Margin',
//         icon: 'seesaw',
//         url: '/exchange/margin',
//         isIcon: true,
//       },
//     ],
//   },
//   {
//     title: 'Buy Crypto',
//     dropdown: [
//       {
//         title: 'Credit card',
//         icon: 'user',
//         url: '/buy-crypto',
//       },
//       {
//         title: 'Bank deposit',
//         icon: 'image',
//         url: '/deposit',
//       },
//     ],
//   },
//   {
//     title: 'Market',
//     url: '/market',
//   },
//   {
//     title: 'Community',
//     dropdown: [
//       {
//         title: 'Telegram',
//         icon: 'telegram',
//         url: '/community/telegram',
//         isIcon: true,
//       },
//       {
//         title: 'X',
//         icon: 'twitter',
//         url: '/community/x',
//       },
//       {
//         title: 'Medium',
//         icon: 'medium',
//         url: '/community/medium',
//         isIcon: true,
//       },
//       {
//         title: 'Discover',
//         icon: 'find-person',
//         url: '/community/discover',
//         isIcon: true,
//       },
//     ],
//   },
//   {
//     title: 'Reward',
//     dropdown: [
//       {
//         title: 'Affiliate',
//         icon: 'affiliate',
//         url: '/reward/affiliate',
//         isIcon: true,
//       },
//       {
//         title: 'Task Centre',
//         icon: 'clipboard',
//         url: '/reward/task-centre',
//         isIcon: true,
//       },
//       {
//         title: 'Referral Program',
//         icon: 'referral',
//         url: '/reward/referral-program',
//         isIcon: true,
//       },
//     ],
//   },
// ]

// function Header() {
//   const [isMenuOpen, setIsMenuOpen] = useState(false);
//   const { colorMode, toggleColorMode } = useColorMode();

//   // Initialize useDarkMode
//   const darkMode = useDarkMode(false, {
//     storageKey: "darkMode", // Key for localStorage
//     onChange: (isDarkMode) => {
//       // Add or remove dark-mode class on body
//       if (isDarkMode) {
//         document.body.classList.add("dark-mode");
//       } else {
//         document.body.classList.remove("dark-mode");
//       }
//     },
//   });

//   const handleOpenNavMenu = () => {
//     setIsMenuOpen(!isMenuOpen);
//   };

//   const handleCloseNavMenu = () => {
//     setIsMenuOpen(false);
//   };


//   return (
//     <nav className={`${styles.appBar} ${darkMode.value ? styles.darkMode : ""}`}>
//       <div className={styles.container}>
//         <div className={styles.toolbar}>
//           <Link to="/" className={styles.logoLink}>
//             <img
//               src="/static/img/logo.png"
//               alt="logo"
//               // width={70}
//               height={60}
//             />
//           </Link>

//           {/* Mobile Menu Button */}
//           <div className={styles.mobileMenu}>
//             <button
//               className={styles.menuButton}
//               aria-label="Open menu"
//               onClick={handleOpenNavMenu}
//             >
//               <span className={styles.menuIcon}>☰</span>
//             </button>

//             {/* Mobile Menu */}
//             {isMenuOpen && (
//               <div className={styles.mobileMenuContent}>
//                 {pages.map((page) => (
//                   <div
//                     key={page}
//                     className={styles.mobileMenuItem}
//                     onClick={handleCloseNavMenu}
//                   >
//                     {page.title}
//                   </div>
//                 ))}
//               </div>
//             )}
//           </div>

//           {/* Desktop Menu */}
//           <div className={styles.desktopMenu}>
//             {pages.map((page) => (
//               <Link
//                 key={page.url} 
//                 to={`${page.url.toLowerCase().replace(/\s+/g, "-")}`}
//                 className={styles.menuLink}
//                 onClick={handleCloseNavMenu}
//             >
//               {page.title}
//             </Link>
            
            
//             ))}
//           </div>

//           {/* Contact Button */}
//           <div className={styles.contactButton}>
//             <button className={styles.fab}>Contact Us</button>

//             {/* Theme Toggle Button */}
//           <button
//             onClick={darkMode.toggle} // Toggle dark mode
//             aria-label="Toggle theme"
//             className={styles.themeToggle}
//           >
//             {darkMode.value ? ( // Check if dark mode is enabled
//               <Icon name="theme-light" size="24" />
//               // <Theme />
//             ) : (
//                 <Icon name="theme-dark" size="24" />
//                 // <Theme />
//             )}
//           </button>
//           </div>
//         </div>
//       </div>
//     </nav>
//   );
// }

// export default Header;

import React, { useState } from 'react'
import cn from 'classnames'
import styles from './Header.module.sass'
import styles2 from './Header.module.css'
import { Link, NavLink, useLocation } from 'react-router-dom'
import { useSelector } from 'react-redux'
import Image from '../Image'
import Dropdown from './Dropdown'
import Settings from './Settings'
import Icon from '../Icon'
import Notifications from './Notifications'
import Theme from '../Theme'
import User from './User'
import PortalEntry from './PortalEntry'
import Layout from './Layout'
import { useTranslation } from 'react-i18next'
import useDarkMode from 'use-dark-mode'

const navigation = [
  {
    title: 'Trade',
    dropdown: [
      {
        title: 'Spot',
        icon: 'refresh',
        url: '/exchange',
        isIcon: true,
      },
      {
        title: 'Quick Buy',
        icon: 'refresh',
        url: '/buy-crypto',
        isIcon: true,
      },
      // {
      //   title: 'Quick Sell',
      //   icon: 'refresh',
      //   url: '/sell-crypto',
      //   isIcon: true,
      // }
      // {
      //   title: 'Futures',
      //   icon: 'newspaper-folded',
      //   url: '/exchange/futures',
      //   isIcon: true,
      // },
      // {
      //   title: 'Margin',
      //   icon: 'seesaw',
      //   url: '/exchange/margin',
      //   isIcon: true,
      // },
    ],
  },
  // {
  //   title: 'Buy Crypto',
  //   dropdown: [
  //     {
  //       title: 'Credit card',
  //       icon: 'user',
  //       url: '/buy-crypto',
  //     },
  //     {
  //       title: 'Bank deposit',
  //       icon: 'image',
  //       url: '/deposit',
  //     },
  //   ],
  // },
  {
    title: 'Market',
    url: '/market',
  },
  {
    title: 'Company',
    dropdown: [
      // {
      //   title: 'Telegram',
      //   icon: 'telegram',
      //   url: '/community/telegram',
      //   isIcon: true,
      // },
      // {
      //   title: 'X',
      //   icon: 'twitter',
      //   url: '/community/x',
      // },
      // {
      //   title: 'Medium',
      //   icon: 'medium',
      //   url: '/community/medium',
      //   isIcon: true,
      // },
      // {
      //   title: 'Discover',
      //   icon: 'find-person',
      //   url: '/community/discover',
      //   isIcon: true,
      // },
      {
        title: 'Contact',
        // icon: 'contact',
        url: '/contact',
        // isIcon: true,
      }
    ],
  },
  // {
  //   title: 'Reward',
  //   dropdown: [
  //     {
  //       title: 'Affiliate',
  //       icon: 'affiliate',
  //       url: '/reward/affiliate',
  //       isIcon: true,
  //     },
  //     // {
  //     //   title: 'Task Centre',
  //     //   icon: 'clipboard',
  //     //   url: '/reward/task-centre',
  //     //   isIcon: true,
  //     // },
  //     // {
  //     //   title: 'Referral Program',
  //     //   icon: 'referral',
  //     //   url: '/reward/referral-program',
  //     //   isIcon: true,
  //     // },
  //   ],
  // },
  {
    title: 'Wallet',
    url: '/dashboard',
  },
]

const Header = ({ headerWide, stickyHeader }) => {
  const [visibleNav, setVisibleNav] = useState(false)
  const { pathname } = useLocation()
  const { t } = useTranslation()

  const { clientData } = useSelector((state) => state?.Profile)

  const darkMode = useDarkMode();

  return (
    <header
      className={cn(stickyHeader ? styles.headerSticky : styles.header, {
        [styles.wide]: headerWide,
      })}
    >
      <PortalEntry />
      <div className={cn('container', styles.container)}>
      <Link to="/" className={styles.logoLink}>
        {/* Desktop Logo */}
      <img
        src={darkMode.value ? "/static/img/logo.png" : "/static/img/logo-2.png"}
        alt="logo"
        height={50}
        className={styles2.desktopLogo}
      />
      {/* Mobile/Tablet Logo */}
      <img
        src={darkMode.value ? "/static/img/small-logo.png" : "/static/img/small-logo-2.png"}
        alt="logo"
        height={50}
        className={styles2.mobileLogo}
      />
           </Link>
        <div className={cn(styles.wrapper, 'wrapper')}>
          <div
            className={cn(styles.wrap, 'wrap', {
              [styles.visible]: visibleNav,
            })}
          >
            <nav className={cn(styles.nav, 'nav')}>
              {navigation.map((x, index) =>
                x.dropdown ? (
                  <Dropdown
                    className={styles.dropdown}
                    key={index}
                    item={x}
                    setValue={setVisibleNav}
                  />
                ) : (
                  <NavLink
                    className={cn(styles.item, 'item', {
                      [styles.active]: pathname === x.url,
                    })}
                    onClick={() => setVisibleNav(false)}
                    to={x.url}
                    key={index}
                  >
                    {t(x.title)}
                  </NavLink>
                ),
              )}
            </nav>
            <NavLink
              className={cn('button-stroke', styles.button, {
                [styles.active]: pathname === '/dashboard',
              })}
              to="/dashboard"
              onClick={() => setVisibleNav(false)}
            >
              {t('Wallet')}
            </NavLink>
            {/* <div className={styles.btns}>
                            <Link
                                className={cn("button-small", styles.button)}
                                to="/sign-up"
                            >
                                Sign Up
                            </Link>
                            <Link
                                className={cn(
                                    "button-stroke button-small",
                                    styles.button
                                )}
                                to="/sign-in"
                            >
                                Login
                            </Link>
                        </div> */}
          </div>
          <Settings className={cn(styles.settings, 'settings')} />
          <div className={cn('control', styles.control)}>
            {pathname.startsWith('/exchange') && <Layout />}
            <NavLink
              className={cn('activity', styles.activity, {
                [styles.active]: pathname === '/activity',
              })}
              to="/activity"
            >
              <Icon name="lightning" size="24" />
            </NavLink>
            {clientData?._id && (
              <Notifications
                className={cn('notifications', styles.notifications)}
              />
            )}
            {/* <NavLink
              className={cn(
                'button-stroke button-small',
                'button',
                styles.button,
                {
                  [styles.active]: pathname === '/dashboard',
                },
              )}
              to="/dashboard"
            >
              {t('Wallet')}
            </NavLink> */}
            <Theme className={cn('theme', styles.theme)} icon />
            {clientData?._id ? (
              <User className={styles.user} />
            ) : (
              <>
                <NavLink className={`button-stroke button-small ${styles2.loginButton}`} to="/login">
                  {t('Login')}
                </NavLink>
                <NavLink
                  className={`button-stroke button-small ${styles2.registerButton}`}
                  to="/register/crypto/live"
                >
                  {t('Sign Up')}
                </NavLink>
              </>
            )}
          </div>
          {/* <div className={styles.btns}>
                        <Link
                            className={cn("button-small", styles.button)}
                            to="/sign-up"
                            onClick={() => setVisibleNav(false)}
                        >
                            Sign Up
                        </Link>
                        <Link
                            className={cn(
                                "button-stroke button-small",
                                styles.button
                            )}
                            to="/sign-in"
                            onClick={() => setVisibleNav(false)}
                        >
                            Login
                        </Link>
                    </div> */}
          <button
            className={cn(styles.burger, {
              [styles.active]: visibleNav,
            })}
            onClick={() => setVisibleNav(!visibleNav)}
          ></button>
        </div>
      </div>
    </header>
  )
}

export default Header