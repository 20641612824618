import React from 'react'
import Form from './Form'

const TPSL = ({
  amount,
  contentBuy,
  contentSell,
  conditionalOrOCO,
  marketOrder,
  limit,
  visible,
  setValue,
  triggerPrice,
  selectedMarket,
}) => {
  return (
    <div>
      <Form
        contentBuy={contentBuy}
        contentSell={contentSell}
        conditionalOrOCO={conditionalOrOCO}
        visible={visible}
        setValue={setValue}
        type='tpsl'
        selectedMarket={selectedMarket}
      />
    </div>
  )
}

export default TPSL
