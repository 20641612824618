// import React, { useState, useEffect, useRef } from 'react'
// import datafeedTest from './datafeed'
// import { widget } from 'charting_library'
// import { fetchOHLCVExchange } from 'apis/klines'
// import useDarkMode from 'use-dark-mode'

// function getLanguageFromURL() {
//   const regex = new RegExp('[\\?&]lang=([^&#]*)')
//   const results = regex.exec(window.location.search)
//   return results === null
//     ? null
//     : decodeURIComponent(results[1].replace(/\+/g, ' '))
// }

// function resolveTf(time) {
//   let result = ''
//   switch (time) {
//     case '1':
//       result = '1m'
//       break
//     case '5':
//       result = '5m'
//       break
//     case '15':
//       result = '15m'
//       break
//     case '30':
//       result = '30m'
//       break
//     case '60':
//       result = '1h'
//       break
//     case '1H':
//       result = '1h'
//       break
//     case '240':
//       result = '4h'
//       break
//     case '1D':
//       result = '1d'
//       break
//     case '1W':
//       result = '1w'
//       break
//     case '1M':
//       result = '1M'
//       break
//     default:
//       break
//   }
//   return result
// }

// export default function TVChartContainer({ symbol, socketKlines }) {
//   const chartContainerRef = useRef()
//   const [timeFrame, setTimeFrame] = useState('1m')
//   const [subscription, setSubscription] = useState(null)
//   const { onReady, searchSymbols, resolveSymbol, getBars } = datafeedTest
//   const darkMode = useDarkMode(false)

//   function updateBar(data) {
//     if (subscription && data && data?.close) {
//       subscription.handler(data)
//     }
//   }
//   // const switchTheme = () => {
//   //   setTheme('Dark')
//   // }
//   const subscribeBarsCustom = (
//     symbolInfo,
//     resolution,
//     onRealtimeCallback,
//     subscriberUID,
//     onResetCacheNeededCallback,
//   ) => {
//     const newSubscription = {
//       symbol: symbolInfo.name,
//       id: subscriberUID,
//       handler: onRealtimeCallback,
//     }
//     setSubscription(newSubscription)
//     setTimeFrame('' + resolution)
//   }

//   const unsubscribeBarsCustom = (subscriberUID) => {
//     console.log('[unsubscribe] Success Unsubscribing')
//     if (subscription?.id === subscriberUID) setSubscription(null)
//   }

//   function removeDuplicates(data) {
//     return [...new Set(data)]
//   }
//   //https://stackoverflow.com/questions/16139452/how-to-convert-big-negative-scientific-notation-number-into-decimal-notation-str?lq=1
//   function noExponents(num) {
//     var data = String(num).split(/[eE]/)
//     if (data.length == 1) return data[0]

//     var z = '',
//       sign = num < 0 ? '-' : '',
//       str = data[0].replace('.', ''),
//       mag = Number(data[1]) + 1

//     if (mag < 0) {
//       z = sign + '0.'
//       while (mag++) z += '0'
//       //eslint-disable-next-line
//       return z + str.replace(/^\-/, '')
//     }
//     mag -= str.length
//     while (mag--) z += '0'
//     return (str + z).substring(0, 10)
//   }
//   const getbarsCustom = async function getBars(
//     symbolInfo,
//     resolution,
//     periodParams,
//     onHistoryCallback,
//     onErrorCallback,
//   ) {
//     // debugger;
//     const { from, to, countBack, firstDataRequest } = periodParams
//     console.log('[getBars]: Method call', symbolInfo)
//     console.log('[getBars]: Period params=> ', resolution, from, to, countBack)
//     try {
//       let payload = {
//         since: from * 1000,
//         to: to * 1000,
//         limit: countBack + 20,
//         symbol,
//         timeframe: resolveTf(resolution),
//       }
//       if (countBack < 100) {
//         onHistoryCallback([], { noData: true })
//       } else {
//         const resp = await fetchOHLCVExchange({ payload })
//         //console.log({api: resp});
//         if (resp?.result) {
//           const bars = resp.result.map((i) => {
//             const bar = {
//               time: i['0'],
//               open: i['1'],
//               high: i['2'],
//               low: i['3'],
//               close: i['4'],
//               volume: i['5'],
//             }
//             return bar
//           })
//           //console.log("NORMALIZED DATA >>> ", {bars});
//           onHistoryCallback(bars, { noData: true })
//         }
//         onHistoryCallback([], { noData: true })
//       }
//     } catch (error) {
//       console.log('[getBars]: Get error', error)
//       onErrorCallback(error)
//     }
//   }
//   // useEffect(() => {
//   //   switchTheme()
//   // }, [appTheme])
//   const defaultProps = {
//     symbol: 'ETH/USDT',
//     interval: '15',
//     datafeedUrl: 'https://demo_feed.tradingview.com',
//     library_path: '../../charting_library/',
//     chartsStorageUrl: 'https://saveload.tradingview.com',
//     chartsStorageApiVersion: '1.1',
//     clientId: 'tradingview.com',
//     userId: 'public_user_id',
//     fullscreen: false,
//     autosize: true,
//     studiesOverrides: {},
//     timezone: 'Etc/UTC',
//     has_intraday: false,
//   }
//   useEffect(() => {
//     const widgetOptions = {
//       symbol: symbol,
//       datafeed: {
//         onReady,
//         searchSymbols,
//         resolveSymbol,
//         getBars: getbarsCustom,
//         subscribeBars: subscribeBarsCustom,
//         unsubscribeBars: unsubscribeBarsCustom,
//       },
//       interval: defaultProps.interval,
//       container: chartContainerRef.current,
//       library_path: defaultProps.library_path,
//       debug: true,
//       locale: getLanguageFromURL() || 'en',
//       disabled_features: [
//         'use_localstorage_for_settings',
//         'header_symbol_search',
//         'legend_widget',
//       ],
//       fullscreen: defaultProps.fullscreen,
//       autosize: defaultProps.autosize,
//       'has-intraday': true,
//       has_daily: false,
//       has_weekly_and_monthly: false,
//       studies_overrides: defaultProps.studiesOverrides,
//       theme: darkMode.value ? 'Dark' : 'Light',
//     }
//     console.log('widgetOptions >>> ', widgetOptions)
//     const tvWidget = new widget(widgetOptions)
//     tvWidget.onChartReady(() => {
//       console.log('Chart has loaded')
//     })

//     return () => {
//       console.log('removing chart >>>')
//       tvWidget.remove()
//     }
//   }, [symbol, darkMode.value])

//   useEffect(() => {
//     const bar = socketKlines.klines.data[socketKlines.klines.data.length - 1]
//     if (bar) {
//       const data = {
//         time: bar?.time * 1000,
//         open: bar?.open,
//         high: bar?.high,
//         low: bar?.low,
//         close: bar?.close,
//         volume: bar?.volume,
//       }
//       updateBar(data)
//     }
//   }, [subscription, symbol, socketKlines])

//   return (
//     <div
//       ref={chartContainerRef}
//       style={{
//         height: '100%',
//       }}
//       className={'TVChartContainer'}
//     />
//   )
// }
// import React, { useState, useEffect, useRef } from 'react';
// import { widget } from 'charting_library';
// import useDarkMode from 'use-dark-mode';
// import pako from 'pako'; // Make sure to install pako package

// function getLanguageFromURL() {
//   const regex = new RegExp('[\\?&]lang=([^&#]*)')
//   const results = regex.exec(window.location.search)
//   return results === null ? null : decodeURIComponent(results[1].replace(/\+/g, ' '))
// }

// function resolveTfToHtx(time) {
//   const mapping = {
//     '1': '1min',
//     '5': '5min',
//     '15': '15min',
//     '30': '30min',
//     '60': '60min',
//     '1H': '60min',
//     '4H': '4hour',
//     '1D': '1day',
//     '1W': '1week',
//     '1M': '1mon'
//   };
//   return mapping[time] || '1min';
// }

// export default function TVChartContainer({ symbol }) {
//   const chartContainerRef = useRef();
//   const websocketRef = useRef(null);
//   const [subscription, setSubscription] = useState(null);
//   const darkMode = useDarkMode(false);

//   // Handle binary WebSocket messages
//   const processWebSocketMessage = (data) => {
//     try {
//       // If the message is binary (Blob), decompress it
//       if (data instanceof Blob) {
//         return new Promise((resolve, reject) => {
//           const reader = new FileReader();
//           reader.onload = async () => {
//             try {
//               // Convert array buffer to Uint8Array
//               const unit8Array = new Uint8Array(reader.result);
//               // Decompress the data using pako
//               const decoded = pako.inflate(unit8Array, { to: 'string' });
//               // Parse the JSON string
//               const parsed = JSON.parse(decoded);
//               resolve(parsed);
//             } catch (err) {
//               reject(err);
//             }
//           };
//           reader.onerror = (error) => reject(error);
//           reader.readAsArrayBuffer(data);
//         });
//       }
      
//       // If the message is already a string, just parse it
//       return Promise.resolve(JSON.parse(data));
//     } catch (error) {
//       console.error('Error processing WebSocket message:', error);
//       return Promise.reject(error);
//     }
//   };

//   const connectWebSocket = (symbolInfo, resolution) => {
//     const htxSymbol = symbolInfo.name.toLowerCase().replace('-', '');
//     const period = resolveTfToHtx(resolution);
//     const wsUrl = `wss://api.htx.com/ws`;

//     if (websocketRef.current) {
//       websocketRef.current.close();
//     }

//     websocketRef.current = new WebSocket(wsUrl);

//     websocketRef.current.onopen = () => {
//       console.log('WebSocket Connected');
//       const subscribeMessage = {
//         sub: `market.${htxSymbol}.kline.${period}`,
//         id: 'id1'
//       };
//       websocketRef.current.send(JSON.stringify(subscribeMessage));
//     };

//     websocketRef.current.onmessage = async (event) => {
//       try {
//         const data = await processWebSocketMessage(event.data);
        
//         // Handle ping to keep connection alive
//         if (data.ping) {
//           websocketRef.current.send(JSON.stringify({ pong: data.ping }));
//           return;
//         }

//         // Handle kline data
//         if (data.tick && subscription) {
//           const tick = data.tick;
//           const bar = {
//             time: tick.id * 1000, // Convert to milliseconds
//             open: parseFloat(tick.open),
//             high: parseFloat(tick.high),
//             low: parseFloat(tick.low),
//             close: parseFloat(tick.close),
//             volume: parseFloat(tick.vol)
//           };
//           subscription.handler(bar);
//         }
//       } catch (error) {
//         console.error('Error handling WebSocket message:', error);
//       }
//     };

//     websocketRef.current.onerror = (error) => {
//       console.error('WebSocket Error:', error);
//     };

//     websocketRef.current.onclose = () => {
//       console.log('WebSocket Disconnected');
//     };
//   };

//   const datafeed = {
//     onReady: (callback) => {
//       callback({
//         supported_resolutions: ['1', '5', '15', '30', '60', '240', '1D', '1W', '1M'],
//         supports_time: true,
//         supports_marks: false,
//         supports_timescale_marks: false
//       });
//     },
    
//     searchSymbols: (userInput, exchange, symbolType, onResultReadyCallback) => {
//       onResultReadyCallback([]);
//     },
    
//     resolveSymbol: (symbolName, onSymbolResolvedCallback, onResolveErrorCallback) => {
//       const symbolInfo = {
//         name: symbolName,
//         description: symbolName,
//         type: 'crypto',
//         session: '24x7',
//         timezone: 'Etc/UTC',
//         minmov: 1,
//         pricescale: 100000000,
//         has_intraday: true,
//         has_daily: true,
//         has_weekly_and_monthly: true,
//         supported_resolutions: ['1', '5', '15', '30', '60', '240', '1D', '1W', '1M'],
//         volume_precision: 8,
//         data_status: 'streaming'
//       };
//       onSymbolResolvedCallback(symbolInfo);
//     },
    
//     getBars: async (symbolInfo, resolution, periodParams, onHistoryCallback, onErrorCallback) => {
//       try {
//         const { from, to, firstDataRequest } = periodParams;
//         const htxSymbol = symbolInfo.name.toLowerCase().replace('/', '');
//         const period = resolveTfToHtx(resolution);
        
//         const response = await fetch(`https://api.htx.com/market/history/kline?symbol=${htxSymbol}&period=${period}&size=2000&from=${from}&to=${to}`);
//         const data = await response.json();
        
//         if (data.status === 'ok' && data.data) {
//           const bars = data.data.map(bar => ({
//             time: bar.id * 1000,
//             open: parseFloat(bar.open),
//             high: parseFloat(bar.high),
//             low: parseFloat(bar.low),
//             close: parseFloat(bar.close),
//             volume: parseFloat(bar.vol)
//           }));
          
//           onHistoryCallback(bars, { noData: false });
//         } else {
//           onHistoryCallback([], { noData: true });
//         }
//       } catch (error) {
//         console.error('Error fetching bars:', error);
//         onErrorCallback(error);
//       }
//     },
    
//     subscribeBars: (symbolInfo, resolution, onRealtimeCallback, subscriberUID, onResetCacheNeededCallback) => {
//       const newSubscription = {
//         symbol: symbolInfo.name,
//         id: subscriberUID,
//         handler: onRealtimeCallback
//       };
//       setSubscription(newSubscription);
//       connectWebSocket(symbolInfo, resolution);
//     },
    
//     unsubscribeBars: (subscriberUID) => {
//       if (websocketRef.current) {
//         websocketRef.current.close();
//       }
//       if (subscription?.id === subscriberUID) {
//         setSubscription(null);
//       }
//     }
//   };

//   useEffect(() => {
//     const widgetOptions = {
//       symbol: symbol,
//       datafeed: datafeed,
//       interval: '15',
//       container: chartContainerRef.current,
//       library_path: '/charting_library/',
//       locale: getLanguageFromURL() || 'en',
//       disabled_features: ['use_localstorage_for_settings'],
//       enabled_features: ['study_templates'],
//       charts_storage_url: 'https://saveload.tradingview.com',
//       charts_storage_api_version: '1.1',
//       client_id: 'tradingview.com',
//       user_id: 'public_user_id',
//       fullscreen: false,
//       autosize: true,
//       theme: darkMode.value ? 'Dark' : 'Light'
//     };

//     const tvWidget = new widget(widgetOptions);

//     return () => {
//       if (websocketRef.current) {
//         websocketRef.current.close();
//       }
//       tvWidget.remove();
//     };
//   }, [symbol, darkMode.value]);

//   return (
//     <div
//       ref={chartContainerRef}
//       className="TVChartContainer"
//       style={{ height: '100%' }}
//     />
//   );
// }
// import React, { useState, useEffect, useRef } from 'react';
// import { widget } from 'charting_library';
// import useDarkMode from 'use-dark-mode';
// import pako from 'pako';

// function getLanguageFromURL() {
//   const regex = new RegExp('[\\?&]lang=([^&#]*)')
//   const results = regex.exec(window.location.search)
//   return results === null ? null : decodeURIComponent(results[1].replace(/\+/g, ' '))
// }

// function resolveTfToHtx(time) {
//   const mapping = {
//     '1': '1min',
//     '5': '5min',
//     '15': '15min',
//     '30': '30min',
//     '60': '60min',
//     '1H': '60min',
//     '4H': '4hour',
//     '1D': '1day',
//     '1W': '1week',
//     '1M': '1mon'
//   };
//   return mapping[time] || '1min';
// }

// export default function TVChartContainer({ symbol = 'ETH-USDT' }) {
//   const chartContainerRef = useRef();
//   const websocketRef = useRef(null);
//   const [subscription, setSubscription] = useState(null);
//   const darkMode = useDarkMode(false);

//   const processWebSocketMessage = (data) => {
//     try {
//       if (data instanceof Blob) {
//         return new Promise((resolve, reject) => {
//           const reader = new FileReader();
//           reader.onload = async () => {
//             try {
//               const unit8Array = new Uint8Array(reader.result);
//               const decoded = pako.inflate(unit8Array, { to: 'string' });
//               const parsed = JSON.parse(decoded);
//               resolve(parsed);
//             } catch (err) {
//               reject(err);
//             }
//           };
//           reader.onerror = (error) => reject(error);
//           reader.readAsArrayBuffer(data);
//         });
//       }
//       return Promise.resolve(JSON.parse(data));
//     } catch (error) {
//       console.error('Error processing WebSocket message:', error);
//       return Promise.reject(error);
//     }
//   };

//   const connectWebSocket = (symbolInfo, resolution) => {
//     const htxSymbol = symbolInfo.name.toLowerCase().replace('-', '');
//     const period = resolveTfToHtx(resolution);
//     const wsUrl = `wss://api.htx.com/ws`;

//     if (websocketRef.current) {
//       websocketRef.current.close();
//     }

//     websocketRef.current = new WebSocket(wsUrl);

//     websocketRef.current.onopen = () => {
//       console.log('WebSocket Connected');
//       const subscribeMessage = {
//         sub: `market.${htxSymbol}.kline.${period}`,
//         id: 'id1'
//       };
//       websocketRef.current.send(JSON.stringify(subscribeMessage));
//     };

//     websocketRef.current.onmessage = async (event) => {
//       try {
//         const data = await processWebSocketMessage(event.data);
        
//         if (data.ping) {
//           websocketRef.current.send(JSON.stringify({ pong: data.ping }));
//           return;
//         }

//         if (data.tick && subscription) {
//           const tick = data.tick;
//           const bar = {
//             time: tick.id * 1000,
//             open: parseFloat(tick.open),
//             high: parseFloat(tick.high),
//             low: parseFloat(tick.low),
//             close: parseFloat(tick.close),
//             volume: parseFloat(tick.vol)
//           };
//           subscription.handler(bar);
//         }
//       } catch (error) {
//         console.error('Error handling WebSocket message:', error);
//       }
//     };

//     websocketRef.current.onerror = (error) => {
//       console.error('WebSocket Error:', error);
//     };

//     websocketRef.current.onclose = () => {
//       console.log('WebSocket Disconnected');
//     };
//   };

//   const datafeed = {
//     onReady: (callback) => {
//       callback({
//         supported_resolutions: ['1', '5', '15', '30', '60', '240', '1D', '1W', '1M'],
//         supports_time: true,
//         supports_marks: false,
//         supports_timescale_marks: false
//       });
//     },
    
//     searchSymbols: (userInput, exchange, symbolType, onResultReadyCallback) => {
//       onResultReadyCallback([]);
//     },
    
//     resolveSymbol: (symbolName, onSymbolResolvedCallback, onResolveErrorCallback) => {
//       const symbolInfo = {
//         name: symbolName,
//         description: symbolName,
//         type: 'crypto',
//         session: '24x7',
//         timezone: 'Etc/UTC',
//         minmov: 1,
//         pricescale: 10000, // Adjusted for typical crypto price precision
//         has_intraday: true,
//         has_daily: true,
//         has_weekly_and_monthly: true,
//         supported_resolutions: ['1', '5', '15', '30', '60', '240', '1D', '1W', '1M'],
//         volume_precision: 8,
//         data_status: 'streaming',
//         // exchange: 'HTX',
//         listed_exchange: 'HTX'
//       };
//       onSymbolResolvedCallback(symbolInfo);
//     },
    
//     getBars: async (symbolInfo, resolution, periodParams, onHistoryCallback, onErrorCallback) => {
//       try {
//         const { from, to, firstDataRequest } = periodParams;
//         const htxSymbol = symbolInfo.name.toLowerCase().replace('-', '');
//         const period = resolveTfToHtx(resolution);
        
//         // HTX API requires size parameter for limit
//         const size = 2000; // Maximum size allowed
        
//         const url = `https://api.htx.com/market/history/kline?symbol=${htxSymbol}&period=${period}&size=${size}`;
//         console.log('Fetching historical data:', url);
        
//         const response = await fetch(url);
//         const data = await response.json();
        
//         console.log('Historical data response:', data);
        
//         if (data.status === 'ok' && Array.isArray(data.data) && data.data.length > 0) {
//           const bars = data?.data?.map(bar => ({
//             time: bar.id * 1000, // Convert to milliseconds
//             open: parseFloat(bar.open),
//             high: parseFloat(bar.high),
//             low: parseFloat(bar.low),
//             close: parseFloat(bar.close),
//             volume: parseFloat(bar.vol)
//           }));
          
//           // Sort bars by time in ascending order
//           bars?.sort((a, b) => a.time - b.time);
          
//           // Filter bars within the requested time range
//           const filteredBars = bars.filter(bar => 
//             bar.time >= from * 1000 && bar.time <= to * 1000
//           );
          
//           console.log(`Processed ${filteredBars.length} bars for the requested period`);
          
//           onHistoryCallback(filteredBars, {
//             noData: filteredBars.length === 0
//           });
//         } else {
//           console.log('No data in response:', data);
//           onHistoryCallback([], { noData: true });
//         }
//       } catch (error) {
//         console.error('Error fetching bars:', error);
//         onErrorCallback(error);
//       }
//     },
    
//     subscribeBars: (symbolInfo, resolution, onRealtimeCallback, subscriberUID, onResetCacheNeededCallback) => {
//       const newSubscription = {
//         symbol: symbolInfo.name,
//         id: subscriberUID,
//         handler: onRealtimeCallback
//       };
//       setSubscription(newSubscription);
//       connectWebSocket(symbolInfo, resolution);
//     },
    
//     unsubscribeBars: (subscriberUID) => {
//       if (websocketRef.current) {
//         websocketRef.current.close();
//       }
//       if (subscription?.id === subscriberUID) {
//         setSubscription(null);
//       }
//     }
//   };

//   useEffect(() => {
//     const widgetOptions = {
//       symbol: symbol,
//       datafeed: datafeed,
//       interval: '15',
//       container: chartContainerRef.current,
//       library_path: '/charting_library/',
//       locale: getLanguageFromURL() || 'en',
//       disabled_features: ['use_localstorage_for_settings'],
//       enabled_features: ['study_templates'],
//       charts_storage_url: 'https://saveload.tradingview.com',
//       charts_storage_api_version: '1.1',
//       client_id: 'tradingview.com',
//       user_id: 'public_user_id',
//       fullscreen: false,
//       autosize: true,
//       theme: darkMode.value ? 'Dark' : 'Light',
//       debug: true // Enable debug mode to see detailed logs
//     };

//     const tvWidget = new widget(widgetOptions);

//     tvWidget.onChartReady(() => {
//       console.log('Chart is ready');
//     });

//     return () => {
//       if (websocketRef.current) {
//         websocketRef.current.close();
//       }
//       tvWidget.remove();
//     };
//   }, [symbol, darkMode.value]);

//   return (
//     <div
//       ref={chartContainerRef}
//       className="TVChartContainer"
//       style={{ height: '100%' }}
//     />
//   );
// }

import React, { useState, useEffect, useRef } from 'react';
import { widget } from 'charting_library';
import useDarkMode from 'use-dark-mode';
import pako from 'pako';

function getLanguageFromURL() {
  const regex = new RegExp('[\\?&]lang=([^&#]*)');
  const results = regex.exec(window.location.search);
  return results === null ? null : decodeURIComponent(results[1].replace(/\+/g, ' '));
}

function resolveTfToHtx(time) {
  const mapping = {
    '1': '1min',
    '5': '5min',
    '15': '15min',
    '30': '30min',
    '60': '60min',
    '1H': '60min',
    '4H': '4hour',
    '1D': '1day',
    '1W': '1week',
    '1M': '1mon',
  };
  return mapping[time] || '1min';
}

export default function TVChartContainer({ symbol = 'ETH-USDT' }) {
  const chartContainerRef = useRef();
  const websocketRef = useRef(null);
  const [subscription, setSubscription] = useState(null);
  const darkMode = useDarkMode(false);
  const [chartHeight, setChartHeight] = useState(window.innerHeight * 0.8); // Default height

  // Handle window resize
  useEffect(() => {
    const handleResize = () => {
      const newHeight = window.innerHeight * 0.8; // Adjust height based on viewport
      setChartHeight(newHeight);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const processWebSocketMessage = (data) => {
    try {
      if (data instanceof Blob) {
        return new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.onload = async () => {
            try {
              const unit8Array = new Uint8Array(reader.result);
              const decoded = pako.inflate(unit8Array, { to: 'string' });
              const parsed = JSON.parse(decoded);
              resolve(parsed);
            } catch (err) {
              reject(err);
            }
          };
          reader.onerror = (error) => reject(error);
          reader.readAsArrayBuffer(data);
        });
      }
      return Promise.resolve(JSON.parse(data));
    } catch (error) {
      console.error('Error processing WebSocket message:', error);
      return Promise.reject(error);
    }
  };

  const connectWebSocket = (symbolInfo, resolution) => {
    const htxSymbol = symbolInfo.name.toLowerCase().replace('-', '');
    const period = resolveTfToHtx(resolution);
    const wsUrl = `wss://api.htx.com/ws`;

    if (websocketRef.current) {
      websocketRef.current.close();
    }

    websocketRef.current = new WebSocket(wsUrl);

    websocketRef.current.onopen = () => {
      console.log('WebSocket Connected');
      const subscribeMessage = {
        sub: `market.${htxSymbol}.kline.${period}`,
        id: 'id1',
      };
      websocketRef.current.send(JSON.stringify(subscribeMessage));
    };

    websocketRef.current.onmessage = async (event) => {
      try {
        const data = await processWebSocketMessage(event.data);

        if (data.ping) {
          websocketRef.current.send(JSON.stringify({ pong: data.ping }));
          return;
        }

        if (data.tick && subscription) {
          const tick = data.tick;
          const bar = {
            time: tick.id * 1000,
            open: parseFloat(tick.open),
            high: parseFloat(tick.high),
            low: parseFloat(tick.low),
            close: parseFloat(tick.close),
            volume: parseFloat(tick.vol),
          };
          subscription.handler(bar);
        }
      } catch (error) {
        console.error('Error handling WebSocket message:', error);
      }
    };

    websocketRef.current.onerror = (error) => {
      console.error('WebSocket Error:', error);
    };

    websocketRef.current.onclose = () => {
      console.log('WebSocket Disconnected');
    };
  };

  const datafeed = {
    onReady: (callback) => {
      callback({
        supported_resolutions: ['1', '5', '15', '30', '60', '240', '1D', '1W', '1M'],
        supports_time: true,
        supports_marks: false,
        supports_timescale_marks: false,
      });
    },

    searchSymbols: (userInput, exchange, symbolType, onResultReadyCallback) => {
      onResultReadyCallback([]);
    },

    resolveSymbol: (symbolName, onSymbolResolvedCallback, onResolveErrorCallback) => {
      const symbolInfo = {
        name: symbolName,
        description: symbolName,
        type: 'crypto',
        session: '24x7',
        timezone: 'Etc/UTC',
        minmov: 1,
        pricescale: 10000, // Adjusted for typical crypto price precision
        has_intraday: true,
        has_daily: true,
        has_weekly_and_monthly: true,
        supported_resolutions: ['1', '5', '15', '30', '60', '240', '1D', '1W', '1M'],
        volume_precision: 8,
        data_status: 'streaming',
        listed_exchange: 'HTX',
      };
      onSymbolResolvedCallback(symbolInfo);
    },

    getBars: async (symbolInfo, resolution, periodParams, onHistoryCallback, onErrorCallback) => {
      try {
        const { from, to, firstDataRequest } = periodParams;
        const htxSymbol = symbolInfo.name.toLowerCase().replace('-', '');
        const period = resolveTfToHtx(resolution);

        // HTX API requires size parameter for limit
        const size = 2000; // Maximum size allowed

        const url = `https://api.htx.com/market/history/kline?symbol=${htxSymbol}&period=${period}&size=${size}`;
        console.log('Fetching historical data:', url);

        const response = await fetch(url);
        const data = await response.json();

        console.log('Historical data response:', data);

        if (data.status === 'ok' && Array.isArray(data.data) && data.data.length > 0) {
          const bars = data?.data?.map((bar) => ({
            time: bar.id * 1000, // Convert to milliseconds
            open: parseFloat(bar.open),
            high: parseFloat(bar.high),
            low: parseFloat(bar.low),
            close: parseFloat(bar.close),
            volume: parseFloat(bar.vol),
          }));

          // Sort bars by time in ascending order
          bars?.sort((a, b) => a.time - b.time);

          // Filter bars within the requested time range
          const filteredBars = bars.filter((bar) => bar.time >= from * 1000 && bar.time <= to * 1000);

          console.log(`Processed ${filteredBars.length} bars for the requested period`);

          onHistoryCallback(filteredBars, {
            noData: filteredBars.length === 0,
          });
        } else {
          console.log('No data in response:', data);
          onHistoryCallback([], { noData: true });
        }
      } catch (error) {
        console.error('Error fetching bars:', error);
        onErrorCallback(error);
      }
    },

    subscribeBars: (symbolInfo, resolution, onRealtimeCallback, subscriberUID, onResetCacheNeededCallback) => {
      const newSubscription = {
        symbol: symbolInfo.name,
        id: subscriberUID,
        handler: onRealtimeCallback,
      };
      setSubscription(newSubscription);
      connectWebSocket(symbolInfo, resolution);
    },

    unsubscribeBars: (subscriberUID) => {
      if (websocketRef.current) {
        websocketRef.current.close();
      }
      if (subscription?.id === subscriberUID) {
        setSubscription(null);
      }
    },
  };

  useEffect(() => {
    const widgetOptions = {
      symbol: symbol,
      datafeed: datafeed,
      interval: '15',
      container: chartContainerRef.current,
      library_path: '/charting_library/',
      locale: getLanguageFromURL() || 'en',
      disabled_features: ['use_localstorage_for_settings'],
      enabled_features: ['study_templates'],
      charts_storage_url: 'https://saveload.tradingview.com',
      charts_storage_api_version: '1.1',
      client_id: 'tradingview.com',
      user_id: 'public_user_id',
      fullscreen: false,
      autosize: true,
      theme: darkMode.value ? 'Dark' : 'Light',
      debug: true, // Enable debug mode to see detailed logs
    };

    const tvWidget = new widget(widgetOptions);

    tvWidget.onChartReady(() => {
      console.log('Chart is ready');
    });

    return () => {
      if (websocketRef.current) {
        websocketRef.current.close();
      }
      tvWidget.remove();
    };
  }, [symbol, darkMode.value]);

  return (
    <div
      ref={chartContainerRef}
      className="TVChartContainer"
      style={{ height: `${chartHeight}px`, width: '100%' }}
    />
  );
}