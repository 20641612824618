// Authentication related pages
import SignIn from 'screens/SignIn'
import ForgotPassword from 'screens/ForgotPassword'
import SignUp from 'screens/SignUp'
import { Navigate } from 'react-router-dom'
import ResetPassword from './../screens/ResetPassword/index';

export default [
  //non Auth routes
  // {
  //   path: '/logout',
  //   component: Logout,
  //   isAuth: false,
  // },
  {
    path: '/login',
    component: SignIn,
    isAuth: false,
    isNotAuthProtected: true,
    headerHide: true,
    footerHide: true,
    headerWide: false,
  },
  {
    path: '/forgot-password',
    component: ForgotPassword,
    isAuth: false,
    isNotAuthProtected: true,
    headerHide: true,
    footerHide: true,
    headerWide: false,
  },
  {
    path: '/reset-password',
    component: ResetPassword,
    isAuth: false,
    isNotAuthProtected: true,
    headerHide: true,
    footerHide: true,
    headerWide: false,
  },
  {
    path: '/register/crypto/live',
    component: SignUp,
    isAuth: false,
    isNotAuthProtected: true,
    headerHide: true,
    footerHide: true,
    headerWide: false,
  },
  {
    path: '/register/crypto/demo',
    component: SignUp,
    isAuth: false,
    isNotAuthProtected: true,
    headerHide: true,
    footerHide: true,
    headerWide: false,
  },
  // {
  //   path: '/reset-password',
  //   component: ResetPassword,
  //   isAuth: false,
  // },
  // {
  //   path: '/unsubscribe',
  //   component: CampaignUnsubscribe,
  //   isAuth: false,
  // },
  {
    path: '*',
    exact: true,
    component: () => <Navigate to="/home" />,
    isAuth: false,
  },
]
