import { useContext, useEffect, useState } from 'react'
import cn from 'classnames'
import styles from './Action.module.sass'
import balanceStyles from '../../../../Balance/Balance.module.sass'
import { Range, getTrackBackground } from 'react-range'
import Icon from '../../../../../../components/Icon'
import Dropdown from 'components/Dropdown'
import Checkbox from 'components/Checkbox'
import TPSLPrice from './TPSLPrice'
import InputDropdown from 'components/InputDropdown'
import { useTranslation } from 'react-i18next'
import { postOrderAPI } from 'apis/orders'
import { useNavigate } from 'react-router'
import { useSelector } from 'react-redux'
import Loader from 'components/Loader'
import { toast } from 'react-toastify'
import SocketContext from 'context/context'
import { usePricingMonitor } from 'hooks/usePricingMonitor'

const COOptions = ['Conditional', 'OCO']
const LoMoOptions = ['Limit order', 'Market order']
const TpLimitOptions = ['TP Limit', 'TP Market']
const SlLimitOptions = ['SL Limit', 'SL Market']

const Action = ({
  // amount,
  buttonText,
  conditionalOrOCO,
  content,
  classButton,
  // totalPrice,
  // price,
  priceSymbol,
  amountSymbol,
  // limit,
  stop,
  title,
  // triggerPrice,
  takeProfit,
  stopLoss,
  actionType,
  orders,
  type,
}) => {
  const { t } = useTranslation()
  const [values, setValues] = useState([10])
  const [conditional, setConditional] = useState(COOptions[0])
  const [isTakeProfit, setIsTakeProfit] = useState(false)
  const [isStopLoss, setIsStopLoss] = useState(false)
  const [loMoOption, setLoMoOptions] = useState(LoMoOptions[0])
  const [tpLimitOption, setTpLimitOption] = useState(TpLimitOptions[0])
  const [slLimitOption, setSlLimitOption] = useState(SlLimitOptions[0])
  const [loading, setLoading] = useState(false)

  const stepPrice = 10
  const minPrice = 0
  const maxPrice = 100

  const navigate = useNavigate()
  const { clientData } = useSelector((state) => state?.Profile)
  
  const [feesAmount, setFeesAmount] = useState()
  const [price, setPrice] = useState()
  const [amount, setAmount] = useState()
  const [totalPrice, setTotalPrice] = useState()
  const [triggerPrice, setTriggerPrice] = useState()
  const [limit, setLimit] = useState()
  const [slTriggerPrice, setSlTriggerPrice] = useState()
  const [slLimitTriggerPrice, setSlLimitTriggerPrice] = useState()

  const { state } = useContext(SocketContext)
  const { klines } = state
  
  const pricingData = usePricingMonitor(klines?.symbol || "ETH/USDT")

  const handleChangeLimitPrices = () =>{
    if (price && amount) {
      setTotalPrice(price * amount)
      handleCalculateFeesValue(price * amount)
    }
  }

  const handleChangeInput = (value) =>{
    setLimit(value)
  }

  const handleCalculateFeesValue = (value) =>{
    let fee = 0
    value = Number(value)
    const tradingFee = clientData?.tradingFeeId
    if (tradingFee?.isPercentage) {
      if (tradingFee?.markets?.[klines?.symbol]) {
        let feeValue = (tradingFee?.markets?.[klines?.symbol]?.value / 100) * value
        if (feeValue > Number(tradingFee?.markets?.[klines?.symbol]?.maxValue) ) {
          fee = Number(tradingFee?.markets?.[klines?.symbol]?.maxValue)
        } else if (feeValue < Number(tradingFee?.markets?.[klines?.symbol]?.minValue)) {
          fee = Number(tradingFee?.markets?.[klines?.symbol]?.minValue)
        } else {
          fee = feeValue
        }
      } else {
        let feeValue = (tradingFee?.value?.$numberDecimal / 100) * value
        if (feeValue  > Number(tradingFee?.maxValue?.$numberDecimal) ) {
          fee = Number(tradingFee?.maxValue?.$numberDecimal)
        } else if (feeValue  < Number(tradingFee?.minValue?.$numberDecimal)) {
          fee = Number(tradingFee?.minValue?.$numberDecimal)
        } else {
          fee = feeValue
        }
      }
    } else {
      if (tradingFee?.markets?.[klines?.symbol]) {
        if (value > tradingFee?.markets?.[klines?.symbol]?.maxValue ) {
          fee = tradingFee?.markets?.[klines?.symbol]?.maxValue
        } else if (value < tradingFee?.markets?.[klines?.symbol]?.minValue) {
          fee = tradingFee?.markets?.[klines?.symbol]?.minValue
        } else {
          fee = tradingFee?.markets?.[klines?.symbol]?.value
        }
      } else {
        if (value > tradingFee?.maxValue?.$numberDecimal ) {
          fee = tradingFee?.maxValue?.$numberDecimal
        } else if (value < tradingFee?.minValue?.$numberDecimal) {
          fee = tradingFee?.minValue?.$numberDecimal
        } else {
          fee = tradingFee?.value?.$numberDecimal
        }
      }
    }
    setFeesAmount(fee)
  }

  const handleChangeInputSLTriggerPrice = (value) =>{
    setSlLimitTriggerPrice(value)
  }

  useEffect(()=>{
    if (type === "limit"){
      handleChangeLimitPrices()
    }
  }, [price, amount])
  
  useEffect(()=>{
    console.log('klinesklines', klines);
    if (type === "market"){
      handleCalculateFeesValue(amount * pricingData?.currentPrice)
    }
  }, [amount])

  useEffect(()=>{
    if (type === "tpsl"){
      handleCalculateFeesValue(totalPrice)
    }
  }, [totalPrice])
  
  
  const handleClickWithData = async () => {
    try {
      if ( clientData?._id ) {
        setLoading(true)
        let params = {}
        if (type === "limit") {
          params = {
            market : { pairName : klines?.symbol },
            type: actionType,
            orderType : type,
            baseAmount : amount,
            quoteAmount : price,
          }
        } else if (type === "market"){
          params = {
            market : { pairName : klines?.symbol },
            type: actionType,
            orderType : type,
            baseAmount : amount,
          }
        } else if (type === "tpsl"){
          if ( conditional.toLowerCase() === 'conditional'){
            let loMoOptionData 
            if (loMoOption === "Limit order"){
              loMoOptionData = "limit"
            } else if (loMoOption === "Market order"){
              loMoOptionData = "market"
            }
            params = {
              market : { pairName : klines?.symbol },
              type: actionType,
              orderType : conditional.toLowerCase(),
              baseAmount : limit,
              price : totalPrice,
              triggerPrice : triggerPrice,
              tpType : loMoOptionData,
            }
          } else if (conditional.toLowerCase() === 'oco'){
            params = {
              market : { pairName : klines?.symbol },
              type: actionType,
              orderType : conditional.toLowerCase(),
              baseAmount : totalPrice,

              tpTriggerPx : triggerPrice,
              tpPrice : limit,
              tpType : tpLimitOption,

              slTriggerPx : slTriggerPrice,
              slType : slLimitOption,
              slPrice : slLimitTriggerPrice,
            }
          }
        }
        
        const response = await postOrderAPI(params)
        setLoading(false)
        
        if (response?.isError) {
          toast.error(response?.message)
        }
        if (response?.isSuccess) {
          toast.success('Order Added Successfully')
        }
      } else {
        navigate('/login')
      }
    } catch (error) {
      setLoading(false)
      toast.error(error?.message)
      console.log(error?.message)
    }
  }
  return (
    <>
      <div className={styles.head}>
        <div className={styles.title}>{t(title)}</div>
        <div className={cn('counter', styles.counter)}>
          <Icon name="wallet" size="16" /> {content}
        </div>
      </div>
      {((type === 'limit') ) && (
        <label className={styles.field}>
          <div className={styles.label}>{t('Price')}</div>
          <input 
            className={styles.input} 
            type="text" 
            name="price" 
            value={price}
            onChange={(e) => setPrice(e.target.value)}
            // required
          />
          <div className={styles.currency}>{priceSymbol}</div>
        </label>
      )}

      {stop && (
        <label className={styles.field}>
          <div className={styles.label}>{t('Stop')}</div>
          {/* <input className={styles.input} type="text" name="stop" required /> */}
          <div className={styles.currency}>{amountSymbol}</div>
        </label>
      )}

      {type === 'tpsl' && (
        <div className={styles.tpslContainer}>
          <div className={styles.dropdown}>
            <Dropdown
              className={balanceStyles.dropdown}
              classDropdownHead={balanceStyles.dropdownArrow}
              classDropdownBody={balanceStyles.dropdownBody}
              classDropdownOption={balanceStyles.dropdownOption}
              value={conditional}
              setValue={setConditional}
              options={COOptions}
            />
          </div>
          {conditional === COOptions[0] ? (
            <>
              <TPSLPrice label="TP trigger price" setTriggerPrice={setTriggerPrice} triggerPrice={triggerPrice} />
              <InputDropdown
                className={balanceStyles.dropdown}
                classDropdownHead={balanceStyles.dropdownArrow}
                classDropdownBody={balanceStyles.dropdownBody}
                classDropdownOption={balanceStyles.dropdownOption}
                value={loMoOption}
                inputFieldName={LoMoOptions[1]}
                setValue={setLoMoOptions}
                options={LoMoOptions}
                adornmentText={priceSymbol}
                handleChangeInput={handleChangeInput}
                inputValue={limit}
              />
              <label className={styles.field}>
                <div className={styles.label}>{t('Total')}</div>
                <input 
                  className={styles.input} 
                  type="text" 
                  name="totalPrice" 
                  value={totalPrice}
                  onChange={(e) => setTotalPrice(e.target.value)}
                />
                <div className={styles.currency}>{priceSymbol}</div>
              </label>
            </>
          ) : (
            <>
              <TPSLPrice label="TP trigger price" setTriggerPrice={setTriggerPrice} triggerPrice={triggerPrice}/>
              <InputDropdown
                className={balanceStyles.dropdown}
                classDropdownHead={balanceStyles.dropdownArrow}
                classDropdownBody={balanceStyles.dropdownBody}
                classDropdownOption={balanceStyles.dropdownOption}
                value={tpLimitOption}
                inputFieldName={TpLimitOptions[1]}
                setValue={setTpLimitOption}
                options={TpLimitOptions}
                adornmentText={priceSymbol}
                handleChangeInput={handleChangeInput}
                inputValue={limit}
              />
              <TPSLPrice label="SL trigger price" setTriggerPrice={setSlTriggerPrice} triggerPrice={slTriggerPrice}/>
              <InputDropdown
                className={balanceStyles.dropdown}
                classDropdownHead={balanceStyles.dropdownArrow}
                classDropdownBody={balanceStyles.dropdownBody}
                classDropdownOption={balanceStyles.dropdownOption}
                value={slLimitOption}
                inputFieldName={SlLimitOptions[1]}
                setValue={setSlLimitOption}
                options={SlLimitOptions}
                adornmentText={priceSymbol}
                handleChangeInput={handleChangeInputSLTriggerPrice}
                inputValue={slLimitTriggerPrice}
              />
              <label className={styles.field}>
                <div className={styles.label}>Total</div>
                <input 
                  className={styles.input} 
                  type="text" 
                  name="totalPrice" 
                  value={totalPrice}
                  onChange={(e) => setTotalPrice(e.target.value)}
                />
                <div className={styles.currency}>{amountSymbol}</div>
              </label>
            </>
          )}
        </div>
      )}

      {/* {(type === 'market') && (
        <label className={styles.field}>
          <div className={styles.label}>Limit</div>
          <input 
            className={styles.input} 
            type="text" 
            name="limit" 
            value={limit}
            onChange={(e) => setLimit(e.target.value)}
            // required
          />
          <div className={styles.currency}>{priceSymbol}</div>
        </label>
      )} */}

      {((type === 'limit') || (type === 'market')) && (
        <label className={styles.field}>
          <div className={styles.label}>{t('Amount')}</div>
          <input 
            className={styles.input} 
            type="text" 
            name="amount" 
            value={amount}
            onChange={(e) => setAmount(e.target.value)}
            // required 
          />
          <div className={styles.currency}>{amountSymbol}</div>
        </label>
      )}
      
      {/* {type === 'tpsl' && (
        <label className={styles.field}>
          <div className={styles.label}>{t('Trigger price')}</div>
          <input 
            className={styles.input} 
            type="text" 
            name="triggerPrice" 
            value={triggerPrice}
            onChange={(e) => setTriggerPrice(e.target.value)}
            // required 
          />
          <div className={styles.currency}>{priceSymbol}</div>
        </label>
      )} */}

      {orders && (
        <InputDropdown
          className={balanceStyles.dropdown}
          classDropdownHead={balanceStyles.dropdownArrow}
          classDropdownBody={balanceStyles.dropdownBody}
          classDropdownOption={balanceStyles.dropdownOption}
          value={loMoOption}
          inputFieldName={LoMoOptions[1]}
          setValue={setLoMoOptions}
          options={LoMoOptions}
          adornmentText={priceSymbol}
        />
      )}

      <div className="ltr">
        <Range
          values={values}
          step={stepPrice}
          min={minPrice}
          max={maxPrice}
          onChange={(values) => setValues(values)}
          renderMark={({ props, index }) => (
            <div
              {...props}
              style={{
                ...props.style,
                height: '6px',
                width: '2px',
                marginTop: '-2px',
                borderRadius: '1px',
                backgroundColor:
                  index * stepPrice < values[0] ? '#3772FF' : '#E6E8EC',
              }}
            />
          )}
          renderTrack={({ props, children }) => (
            <div
              onMouseDown={props.onMouseDown}
              onTouchStart={props.onTouchStart}
              style={{
                ...props.style,
                height: '36px',
                display: 'flex',
                width: '100%',
              }}
            >
              <div
                ref={props.ref}
                style={{
                  height: '2px',
                  width: '100%',
                  borderRadius: '1px',
                  background: getTrackBackground({
                    values,
                    colors: ['#3772FF', '#E6E8EC'],
                    min: minPrice,
                    max: maxPrice,
                  }),
                  alignSelf: 'center',
                }}
              >
                {children}
              </div>
            </div>
          )}
          renderThumb={({ props, isDragged }) => (
            <div
              {...props}
              style={{
                ...props.style,
                height: '18px',
                width: '18px',
                borderRadius: '50%',
                backgroundColor: '#F4F5F6',
                border: '4px solid #777E90',
                boxShadow: '0px 8px 16px -8px rgba(15, 15, 15, 0.2)',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <div
                style={{
                  position: 'absolute',
                  top: '-27px',
                  color: '#FCFCFD',
                  fontWeight: '600',
                  fontSize: '13px',
                  lineHeight: '16px',
                  fontFamily: 'Poppins',
                  padding: '2px 6px',
                  borderRadius: '6px',
                  backgroundColor: '#777E90',
                }}
              >
                {values[0].toFixed(0)}%
              </div>
            </div>
          )}
        />
      </div>

      {(type === 'limit') && (
        <label className={styles.field}>
          <div className={styles.label}>{t('Total')}</div>
          <input 
            className={styles.input} 
            type="text" 
            name="totalPrice" 
            value={totalPrice}
            onChange={(e) => setTotalPrice(e.target.value)}
            // required 
            disabled
          />
          <div className={styles.currency}>{priceSymbol}</div>
        </label>
      )}

      {/* {stopLoss && takeProfit && (
        <div className={styles.checkboxes}>
          {takeProfit && (
            <Checkbox
              className={styles.checkbox}
              value={isTakeProfit}
              onChange={() => setIsTakeProfit(!isTakeProfit)}
              content="Take Profit"
            />
          )}
          {stopLoss && (
            <Checkbox
              className={styles.checkbox}
              value={isStopLoss}
              onChange={() => setIsStopLoss(!isStopLoss)}
              content="Stop Loss"
            />
          )}
        </div>
      )} */}

      {isTakeProfit && (
        <>
          <TPSLPrice label="TP trigger price" />
          <div className={styles.orderPrice}>
            <TPSLPrice label="TP order price" isMarket marketLabel="Market" />
          </div>
        </>
      )}
      {isStopLoss && (
        <>
          <TPSLPrice label="SL trigger price" />
          <div className={styles.orderPrice}>
            <TPSLPrice label="SL order price" isMarket marketLabel="Market" />
          </div>
        </>
      )}
      <label className={`${styles.field} ${styles.fieldPadding}`}>
        <div className={styles.label}>Fees Amount : {feesAmount || 0} USDT</div>
      </label>
      <button
        type='button' 
        disabled={loading}
        className={cn(classButton, styles.button)}
        onClick={handleClickWithData}
      >
        {loading ? <Loader /> :  t(buttonText)}
      </button>
    </>
  )
}

export default Action
